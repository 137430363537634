/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState, useContext } from 'react'
import '../../../styles/routes_map/routes.css'
import Search_input from '../../UI/search_input'
import Cars_for_routes from './cars_for_draw_routes'
import Period_for_routes from './period_for_routes'
import Routes_info_list from './routes_info_list'
import map_service from '../../../API/map_api'
import axios_api from '../../../API/axios_api'
import car_data from '../../../API/car_data'
import { MapContext } from '../../../context/index'
import { useCookies } from 'react-cookie'
import ReactLoading from 'react-loading'
import Btn_defolt from '../../UI/btn_defolt'

import Modal_choose_car from '../zones/modal_add_car_to_zone'
import Groups_cars_filter from '../../UI/groups_cars_filter.jsx'

import SimpleBar from 'simplebar-react'

import { useSelector } from 'react-redux'

let reqId = 0

export default function Car_routes_window_routes(props) {
  const user_current = useSelector((state) => state.user_current)
  const window_height = useSelector((state) => state.window_height)
  const simplebar_height = window_height - 70 - 24 - 24 - 55 - 88 - 59

  const [cookies] = useCookies(['csrftoken'])

  const [modal_choose_car_active, setActive_modal_choose_car] = useState(false)
  const [group_sort_id, setGroup_sort_id] = useState('ALL')
  const [search_choose_car, setSearch_choose_car] = useState('')

  const [is_load_routes, setIs_load_routes] = useState(false)
  const [status_load_routes, setStatus_load_routes] = useState(200)

  let { map_object } = useContext(MapContext)

  let class_visible
  if (props.item == 'routes') {
    class_visible = ''
  } else {
    class_visible = 'divs_window_routes'
    hide_window_routes()
  }

  async function hide_window_routes() {
    await map_service.hide_routes(map_object)
    await map_service.remove_markers(map_object)
    await map_service.draw_markers(map_object, 'ALL', user_current)
  }

  const [start_end, setStartEnd] = useState({
    start: init_date(0),
    end: init_date(0),
  })

  map_object.period_for_routes = start_end

  useEffect(() => {
    map_object.info_draw_routes.reload = true
    if (map_object.info_draw_routes.car) {
      if (props.item == 'routes') {
        draw_routes()
      }
    }
  }, [start_end, map_object.info_draw_routes.car])

  useEffect(() => {
    if (map_object.info_draw_routes.car) {
      if (props.item == 'routes') {
        draw_routes()
      }
    }
  }, [props.item])

  map_object.info_draw_routes.color_id_for_cards = 0

  let searched_array = []

  if (map_object.cars) {
    map_object.cars.map((car) => {
      if (car.car_number == null) {
        car.car_number = '-'
      }
      if (car.brand == null) {
        car.brand = '-'
      }
      if (car.phone_number == null) {
        car.phone_number = '-'
      }
      if (car.mileage == null) {
        car.mileage = '-'
      }
      if (car.daily_mileage == null) {
        car.daily_mileage = '-'
      }
      if (car.fuel_consumption == null) {
        car.fuel_consumption = '-'
      }
      if (!search_choose_car) {
        if (group_sort_id == 'ALL' || group_sort_id == car.car_group) {
          searched_array.push(car)
        }
      } else {
        if (
          car.car_number &&
          car.car_number.toUpperCase().includes(search_choose_car.toUpperCase())
        ) {
          if (group_sort_id == 'ALL' || group_sort_id == car.car_group) {
            searched_array.push(car)
          }
        }
      }
    })
  }

  function open_choose_car() {
    setActive_modal_choose_car(true)
  }

  function car_click(e) {
    let id = e.currentTarget.getAttribute('data_id')
    for (let car of map_object.cars) {
      if (car.id == id) {
        map_object.info_draw_routes.car = car
        setActive_modal_choose_car(false)
      }
    }
  }

  return (
    <div className={class_visible} style={{ height: '100%' }}>
      <Cars_for_routes info_draw_routes={map_object.info_draw_routes} onClick={open_choose_car} />
      {map_object.info_draw_routes.car ? (
        <div style={{ height: 'calc(100% - 70px)' }}>
          <Period_for_routes start_end={start_end} setStartEnd={setStartEnd} />
          {is_load_routes && map_object.routes ? (
            <>
              {status_load_routes === 200 ? (
                <div style={{ marginTop: '20px' }}>
                  <SimpleBar style={{ maxHeight: simplebar_height }}>
                    <Routes_info_list />
                  </SimpleBar>
                </div>
              ) : (
                <div className='div_routes_info_list' style={{ marginTop: '20px' }}>
                  <div className='no_routes'>Ошибка загрузки маршрута за этот период</div>
                </div>
              )}
            </>
          ) : (
            <div
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ReactLoading type={'spin'} color='#6486FB' />
            </div>
          )}
        </div>
      ) : (
        <div className='div_choose_car_for_routes'>
          <div className='choose_car_for_routes'>Выберите авто для отображения поездок</div>
          <div style={{ marginTop: '15px' }}>
            <Btn_defolt
              color={'#EFF3FF'}
              text_color={'#6486FB'}
              hover_color={'#dbe4ff'}
              text={'Выбрать'}
              height={'40px'}
              width={'200px'}
              onClick={open_choose_car}
            />
          </div>
        </div>
      )}
      <Modal_choose_car
        active={modal_choose_car_active}
        setActive={(e) => setActive_modal_choose_car(false)}
      >
        <div className='text_name_add_cars_to_zone'>Выбор авто</div>
        <div className='car_list_new_zone' style={{ height: '390px' }}>
          {map_object.cars ? (
            <>
              <div className='select_group_new_zone' style={{ marginTop: '20px' }}>
                <Groups_cars_filter
                  current_value={group_sort_id}
                  all_values={map_object.groups || []}
                  setValue={setGroup_sort_id}
                  status={'active'}
                  width={'200px'}
                  height={'36px'}
                  cars={map_object.cars || []}
                />
              </div>
              <div className='search_new_zone' style={{ marginTop: '20px' }}>
                <Search_input placeholder={'Введите  гос. номер'} change={setSearch_choose_car} />
              </div>
              <SimpleBar style={{ maxHeight: 300 }}>
                {searched_array.map((car) => (
                  <div key={car.id} className={'div_choose_car_table'}>
                    <div
                      className={'row_choose_car_table'}
                      key={car.id}
                      data_id={car.id}
                      onClick={(e) => car_click(e)}
                    >
                      <div className='td_car_icon_choose_car_table'>
                        <img
                          src={car_data.get_svg_car(car.color)}
                          style={{ height: '25px' }}
                          alt=''
                        />
                      </div>
                      <div className='td_car_number_choose_car_table'>
                        <div className='number_choose_car_table'>{car.car_number}</div>
                        <div className='brand_choose_car_table'>{car.brand}</div>
                      </div>
                      <div className='td_car_phone_choose_car_table'>{car.phone_number}</div>
                    </div>
                    <div className='div_border_bottom_choose_car_table'>
                      <div className='border_bottom_choose_car_table'></div>
                    </div>
                  </div>
                ))}
              </SimpleBar>
            </>
          ) : (
            <div
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ReactLoading type={'spin'} color='#6486FB' />
            </div>
          )}
        </div>
      </Modal_choose_car>
    </div>
  )

  async function draw_routes() {
    map_object.info_draw_routes.draw_routes = false
    console.log('draw_routes: ', start_end)
    await map_service.hide_routes(map_object)
    await map_service.remove_markers(map_object)
    let routes
    if (map_object.info_draw_routes.reload) {
      setIs_load_routes(false)
      reqId++
      map_object.routes = []
      map_object.info_draw_routes.reload = false
      routes = await axios_api.get_routes(
        map_object.info_draw_routes.car.id,
        start_end,
        cookies.csrftoken,
        reqId,
      )
      if (reqId !== routes.reqId) {
        return
      }
      if (routes.status !== 200) {
        map_object.routes = []
      } else {
        map_object.routes = routes.data
      }

      setStatus_load_routes(routes.status)
      setIs_load_routes(true)
    }
    console.log('routes : ', map_object.routes)

    await map_service.draw_markers(map_object, map_object.info_draw_routes.car.id, user_current)

    if (map_object.routes && map_object.routes.length !== 0) {
      await map_service.draw_routes(map_object.info_draw_routes.car.id, map_object)
    }
  }

  function init_date(minus_days) {
    let date = new Date().getTime()

    let day_in_mil = 86400000

    date = date + minus_days * day_in_mil

    date = new Date(date)

    let month

    if (parseFloat(date.getMonth()) + 1 < 10) {
      month = '0' + (parseFloat(date.getMonth()) + 1)
    } else {
      month = parseFloat(date.getMonth()) + 1
    }

    date = date.getDate() + '.' + month + '.' + date.getFullYear()

    return date
  }
}
