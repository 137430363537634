import React, { useEffect, useState, useContext } from 'react'
import axios_api from '../../API/axios_api'
import '../../styles/dashboard/card_driving_issues.css'
import { useCookies } from 'react-cookie'
import SimpleBar from 'simplebar-react'
import Tippy from '@tippyjs/react/headless'
import ReactLoading from 'react-loading'
import Basic_select from '../UI/basic_select.jsx'
import car_data from '../../API/car_data'
import Big_modal from './big_modal.jsx'
import Btn_defolt from '../UI/btn_defolt.jsx'

export default function Card_driving_issues(props) {
  const [cookies] = useCookies(['csrftoken'])

  const [cars_driving_issues, setCars_driving_issues] = useState([])
  const [car_driving_issues_detail, setCar_driving_issues_detail] = useState(null)
  const [car_for_detaild, setCar_for_detaild] = useState({
    car_number: '-',
  })
  const [is_loading, setIs_loading] = useState(true)
  const [is_loading_detail, setIs_loading_detail] = useState(true)

  const [modal_detail_active, setActive_modal_detail] = useState(false)

  const [up_down_filter_value, setUp_down_filter_value] = useState('DOWN')
  const up_down_filter = [
    ['По возрастанию', 'UP'],
    ['По убыванию', 'DOWN'],
  ]

  const [time_range_filter_value, setTime_range_filter_value] = useState('WEEK')
  const time_range_filter = [
    ['За день', 'DAY'],
    ['За неделю', 'WEEK'],
    ['За месяц', 'MONTH'],
  ]

  useEffect(() => {
    get_cars_driving_issues()
  }, [props.group_sort_id, time_range_filter_value])

  let cars_driving_issues_filtered = cars_driving_issues

  if (cars_driving_issues_filtered) {
    if (up_down_filter_value == 'UP') {
      cars_driving_issues_filtered.sort(function (a, b) {
        return a.issues_count - b.issues_count
      })
    } else {
      cars_driving_issues_filtered.sort(function (a, b) {
        return b.issues_count - a.issues_count
      })
    }
    for (let car of cars_driving_issues_filtered) {
      if (!car.car_number) {
        car.car_number = '-'
      }
      if (!car.brand) {
        car.brand = '-'
      }
      if (!car.model) {
        car.model = '-'
      }
    }
  }

  function show_detaied_driving_issue(car) {
    setCar_for_detaild(car)
    setActive_modal_detail(true)
    get_car_driving_issues_detail(car.id)
  }

  function convert_to_date(date) {
    let new_date = date.split('T')[0]
    let day = new_date.split('-')[2]
    let month = new_date.split('-')[1]
    let year = new_date.split('-')[0]

    // payment.date = ''
    new_date = day + '.' + month + '.' + year

    return new_date
  }

  function convert_to_time(date) {
    let new_date = date.split('T')[1]
    let hours = new_date.split(':')[0]
    let minutes = new_date.split(':')[1]

    // payment.date = ''
    new_date = hours + ':' + minutes

    return new_date
  }

  return (
    <div className='main_div_card_driving_issues'>
      <div className='main_text_card_cars_fuel' style={{ marginBottom: '20px' }}>
        Топ авто по качеству вождения
      </div>
      <div style={{ marginTop: '20px', display: 'flex', marginBottom: '10px' }}>
        <div>
          <Basic_select
            current_value={up_down_filter_value}
            all_values={up_down_filter}
            setValue={setUp_down_filter_value}
            width={'180px'}
          />
        </div>
        <div style={{ marginLeft: '16px' }}>
          <Basic_select
            current_value={time_range_filter_value}
            all_values={time_range_filter}
            setValue={setTime_range_filter_value}
            width={'135px'}
          />
        </div>
      </div>
      {is_loading == false ? (
        <>
          {cars_driving_issues_filtered.length != 0 ? (
            <SimpleBar style={{ maxHeight: '300px' }}>
              {cars_driving_issues_filtered.map((car) => (
                <div key={car.id} data_id={car.id} onClick={() => show_detaied_driving_issue(car)}>
                  <div className='row_table_driving_issues' data_id={car.id}>
                    <div className='td_car_number_table_driving_issues'>
                      <img
                        src={car_data.get_svg_car(car.color)}
                        style={{ height: '25px', marginRight: '15px' }}
                      />
                      <div className=''>
                        <div className='table_driving_issues_number'>{car.car_number}</div>
                        <div className='table_driving_issues_brand'>
                          {car.brand} {car.model}
                        </div>
                      </div>
                    </div>
                    <div className='td_mil_table_driving_issues'>Нарушений: {car.issues_count}</div>
                  </div>
                  <div className='div_border_bottom_table_driving_issues'>
                    <div className='border_bottom_table_driving_issues'></div>
                  </div>
                </div>
              ))}
            </SimpleBar>
          ) : (
            <div className='no_data_driving_issues' style={{ height: '345px' }}>
              Нет данных
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ReactLoading type={'spin'} color='#6486FB' />
        </div>
      )}
      <Big_modal active={modal_detail_active} setActive={setActive_modal_detail}>
        <div className='text_create_group'>Нарушения {car_for_detaild.car_number}</div>
        <div style={{ width: '100%' }}>
          {is_loading == false ? (
            <>
              {car_driving_issues_detail && car_driving_issues_detail.driving_issues.length != 0 ? (
                <div style={{ width: '100%', marginTop: '50px', marginBottom: '40px' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '10px',
                      paddingLeft: '16px',
                      paddingRight: '16px',
                    }}
                  >
                    <div className='thead_table_issue_detail' style={{ width: '85px' }}>
                      Дата и время
                    </div>
                    <div className='thead_table_issue_detail' style={{ width: '130px' }}>
                      Нарушение
                    </div>
                    <div className='thead_table_issue_detail' style={{ width: '90px' }}>
                      Скорость
                    </div>
                    <div className='thead_table_issue_detail' style={{ width: '170px' }}>
                      Координаты
                    </div>
                  </div>
                  {car_driving_issues_detail.driving_issues.map((issue) => (
                    <div key={issue.id}>
                      <div className={'row_table_issue_detail'}>
                        <div className='td_date_table_issue_detail' style={{ width: '85px' }}>
                          <div>{convert_to_date(issue.dt)}</div>
                          <div style={{ fontSize: '12px' }}>{convert_to_time(issue.dt)}</div>
                        </div>
                        <div className='td_type_table_car_list' style={{ width: '130px' }}>
                          {issue.type_description}
                        </div>
                        <div className='td_speed_table_issue_detail' style={{ width: '90px' }}>
                          {issue.speed} км/ч
                        </div>
                        <div className='td_location_table_issue_detail' style={{ width: '170px' }}>
                          {/* широта, долгота */}({issue.latitude}, {issue.longitude})
                        </div>
                      </div>
                      <div className='div_border_bottom_table_issue_detail'>
                        <div className='border_bottom_table_issue_detail'></div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className='no_data_driving_issues' style={{ height: '250px' }}>
                  У авто отсутствуют нарушения
                </div>
              )}
            </>
          ) : (
            <div
              style={{
                height: '250px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ReactLoading type={'spin'} color='#6486FB' />
            </div>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Btn_defolt
            color={'#EFF3FF'}
            text_color={'#6486FB'}
            hover_color={'#dbe4ff'}
            text={'Закрыть'}
            height={'40px'}
            width={'300px'}
            onClick={() => setActive_modal_detail(false)}
          />
        </div>
      </Big_modal>
    </div>
  )

  async function get_cars_driving_issues() {
    setCars_driving_issues([])
    setIs_loading(true)
    let new_cars_driving_issues = await axios_api.get_cars_driving_issues(
      time_range_filter_value,
      props.group_sort_id,
      cookies.csrftoken,
    )
    console.log('dashboard new_cars_driving_issues : ', new_cars_driving_issues)
    setCars_driving_issues(new_cars_driving_issues)
    setIs_loading(false)
  }

  async function get_car_driving_issues_detail(car_id) {
    setCar_driving_issues_detail(null)
    setIs_loading_detail(true)
    let new_driving_issues_detail = await axios_api.get_car_driving_issues_detail(
      car_id,
      cookies.csrftoken,
    )
    console.log('dashboard new_driving_issues_detail : ', new_driving_issues_detail)
    setCar_driving_issues_detail(new_driving_issues_detail)
    setIs_loading_detail(false)
  }
}
