import React, { useEffect, useState, useContext } from 'react'
import '../../../styles/routes_map/car_table.css'

import Table_item from './car_table_item_window_routes'
import { useSelector } from 'react-redux'

export default function Car_table_window_routes(props) {
  //console.log('props : ', props)
  const park_current = useSelector((state) => state.park_current)

  let searched_array = []

  props.cars.map((car) => {
    const isCarInGroup = car.car_group.includes(Number(props.group_sort_id))
    if (!props.search) {
      if (props.group_sort_id == 'ALL' || isCarInGroup) {
        searched_array.push(car)
      }
    } else {
      if (
        car.car_number &&
        car.car_number.toUpperCase().includes(props.search.toUpperCase()) &&
        (props.group_sort_id == 'ALL' || isCarInGroup)
      ) {
        searched_array.push(car)
      }
    }
  })

  return (
    <div>
      {searched_array.length ? (
        <>
          {searched_array.map((car) => (
            <Table_item
              car={car}
              key={car.id}
              setNavBar_item={props.setNavBar_item}
              can_block_car_on_moving={park_current ? park_current.can_block_car_on_moving : true}
            />
          ))}
        </>
      ) : (
        <div className='no_cars'>Нет авто</div>
      )}
    </div>
  )
}
