import React, { useRef, useEffect, useState, useContext } from 'react'
import Draw_nav_bar_main from '../components/navbar/draw_nav_bar'
import Draw_drivers from '../components/drivers/draw_drivers.jsx'

import { useDispatch, useSelector } from 'react-redux'
import resize_action from '../actions/window_actions.js'
import page_change from '../actions/router_actions.js'
import { MapContext } from '../context/index'
import $ from 'jquery'
import Modal_bills from '../components/map/bills_modal/modal_bills.jsx'

const MainDrivers = () => {
  let { map_object, set_map_object } = useContext(MapContext)
  const payment_block = useSelector((state) => state.payment_block)
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = 'Список водителей | ControlGPS'
    let height = $(document).height()
    let width = $(document).width()
    dispatch(resize_action(height, width))
    dispatch(page_change('drivers_list'))
    map_object.markers = []
    map_object.map = null
  }, [])

  return (
    <>
      <Modal_bills hideIfNotMaxDiff={true} />
      {!payment_block && <Draw_drivers />}
    </>
  )
}

export default MainDrivers
