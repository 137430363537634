import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax

export default class map_service {
  static async zoom_on_car(map_object, car_id) {
    console.log('zoom_on_car : ', car_id)

    map_object.cars.map(function (car) {
      if (car.id == car_id) {
        if (car.locations[0]) {
          let car_latitude = car.locations[0].latitude
          let car_longitude = car.locations[0].longitude
          map_object.map.flyTo({
            center: [car_longitude, car_latitude],
            zoom: 15,
            speed: 0.1,
            curve: 1,
            duration: 3000,
            essential: true,
            easing(t) {
              return t
            },
          })
        }
      }
    })
  }

  static async remove_markers(map_object) {
    // console.log('map_object.map : ', map_object.map)
    if (!map_object.map) {
      return
    }

    if (map_object.markers) {
      if (map_object.markers.length != 0) {
        map_object.markers.map(function (car_marker) {
          car_marker.marker.remove()
        })
        map_object.markers = []
      }
    }
  }

  static async draw_markers(map_object, car_id, user_current) {
    if (!map_object.map) {
      return
    }
    if (!map_object.cars) {
      return
    }

    map_object.cars.map(function (car) {
      if (car_id == 'ALL' || car_id == car.id) {
        if (car.locations.length == 0) return

        if (!check_valid_coord(car.locations[0].longitude, car.locations[0].latitude)) return

        let find_marker = map_object.markers.find((marker) => marker.id == car.id)

        let new_marker = {
          id: car.id,
          marker: null,
        }

        if (!find_marker) {
          if (car.locations.length != 0) {
            let car_latitude = car.locations[0].latitude
            let car_longitude = car.locations[0].longitude

            new_marker.old_velocity = car.locations[0].velocity

            let popup = make_popup(car)
            let marker_insert_to_map = make_marker(car, user_current)

            new_marker.marker = new mapboxgl.Marker({
              element: marker_insert_to_map,
              rotation: '0',
            })
              .setLngLat([car_longitude, car_latitude])
              .setPopup(
                new mapboxgl.Popup({ closeButton: false, openOnClick: false })
                  .setMaxWidth('400px')
                  .setHTML(popup),
              )
              .addTo(map_object.map)

            move_number_text(car, user_current)

            new_marker.marker.setLngLat([car_longitude, car_latitude])

            let markerDiv = new_marker.marker.getElement()

            markerDiv.addEventListener('mouseenter', (e) =>
              mouse_marker('enter', new_marker.marker, popup, e),
            )
            markerDiv.addEventListener('mouseleave', (e) =>
              mouse_marker('leave', new_marker.marker, popup, e),
            )

            map_object.markers.push(new_marker)
          }
        } else {
          if (car.locations.length != 0) {
            let car_latitude = car.locations[0].latitude
            let car_longitude = car.locations[0].longitude
            let car_course = car.locations[0].course
            let car_velocity = car.locations[0].velocity

            if (
              (car_velocity != 0 && find_marker.old_velocity == 0) ||
              (car_velocity == 0 && find_marker.old_velocity != 0)
            ) {
              if (find_marker.marker) {
                let count = 0
                for (let car_marker of map_object.markers) {
                  if (car_marker.id == find_marker.id) {
                    car_marker.marker.remove()
                    map_object.markers.splice(count, 1)
                  }
                  count++
                }
              } else {
                find_marker.id = -1
              }

              let popup = make_popup(car)
              let marker_insert_to_map = make_marker(car, user_current)

              new_marker.marker = new mapboxgl.Marker({
                element: marker_insert_to_map,
                rotation: '0',
              })
                .setLngLat([car_longitude, car_latitude])
                .setPopup(
                  new mapboxgl.Popup({ closeButton: false, openOnClick: false })
                    .setMaxWidth('400px')
                    .setHTML(popup),
                )
                .addTo(map_object.map)

              move_number_text(car, user_current)

              new_marker.marker.setLngLat([car_longitude, car_latitude])

              let markerDiv = new_marker.marker.getElement()

              markerDiv.addEventListener('mouseenter', (e) =>
                mouse_marker('enter', new_marker.marker, popup, e),
              )
              markerDiv.addEventListener('mouseleave', (e) =>
                mouse_marker('leave', new_marker.marker, popup, e),
              )

              new_marker.old_velocity = car_velocity
              map_object.markers.push(new_marker)
            } else {
              find_marker.marker.setLngLat([car_longitude, car_latitude])
              find_marker.old_velocity = car_velocity
              let marker_way = 'marker_' + car.id
              if (document.getElementsByClassName(marker_way)[0]) {
                document.getElementsByClassName(marker_way)[0].style.transform =
                  'rotate(' + car_course + 'deg)'
              }
            }
          } else {
            if (find_marker.marker) {
              let count = 0
              for (let car_marker of map_object.markers) {
                if (car_marker.id == find_marker.id) {
                  car_marker.marker.remove()
                  map_object.markers.splice(count, 1)
                }
                count++
              }
            } else {
              find_marker.id = -1
            }
          }
        }
      }
    })

    function check_valid_coord(longitude, latitude) {
      if (-180 <= longitude <= 180 && -90 <= latitude <= 90) {
        return true
      }
      console.log('longitude error : ', longitude)
      console.log('longitude error : ', latitude)
      return false
    }

    function make_popup(car) {
      let popup =
        '<div class="popup_car_big_div">' +
        '<div class="popup_car_div">' +
        '<span class="popup_car_number">' +
        car.car_number +
        '</span>' +
        '<span class="popup_car_brend">' +
        car.brand +
        '</span>' +
        '</div>'

      for (let input of car.inputs) {
        let html_input =
          '<div class="popup_parametrs_div">' +
          '<span class="popup_text_name">' +
          input.verbose_name +
          '</span>' +
          '<span class="popup_text_value">' +
          Math.round(input.value) +
          ' ' +
          input.units +
          '</span>' +
          '</div>'

        popup = popup + html_input
      }
      popup = popup + '</div>'

      return popup
    }

    function make_marker(car, user_current) {
      let car_course = car.locations[0].course
      let car_velocity = car.locations[0].velocity

      let marker_insert_to_map = document.createElement('div')

      if (!car.color) {
        car.color = 'LIGHT_BLUE'
      }

      let class_numver = ''
      if (car_velocity == 0) {
        class_numver = 'stop'
      }

      let size = user_current.icons_size.toFixed(1).toString()

      if (size.length != 3) {
        size = [size, '0']
      } else {
        size = [size[0], size[2]]
        // size = [0,8]
      }

      // console.log('size : ', size[0] + '_' + size[1])

      let marker_div =
        '<div class="div_marker">' +
        '<div class="marker marker_' +
        car.id +
        ' marker_' +
        car.color +
        ' marker_size_' +
        size[0] +
        '_' +
        size[1] +
        '" style="transform: rotate(' +
        car_course +
        'deg);"></div>' +
        '<span id="marker_car_number_span_' +
        car.id +
        '" class="marker_car_number_span marker_car_number_span_' +
        size[0] +
        '_' +
        size[1] +
        '"><span class="marker_car_number_text ' +
        class_numver +
        ' marker_car_number_text_' +
        size[0] +
        '_' +
        size[1] +
        '">' +
        car.car_number +
        '</span></span>' +
        '</div>'

      marker_insert_to_map.insertAdjacentHTML('beforeend', marker_div)

      return marker_insert_to_map
    }

    function move_number_text(car, user_current) {
      let element = document.getElementById('marker_car_number_span_' + car.id)
      let width = element.offsetWidth
      let move_px = -Math.round(width / 2.45)
      // console.log('move_px : ', move_px)
      element.style.right = move_px + 'px'
    }

    function mouse_marker(event, marker, smth, e) {
      // console.log('e : ', e)
      let popap = marker.getPopup()

      if (event == 'enter') {
        if (!popap.isOpen()) {
          marker.togglePopup()
        }
      } else {
        let toElementClass = null
        // console.log('e : ', e)
        if (e.toElement) {
          if (e.toElement.className) {
            toElementClass = e.toElement.className
            // console.log('toElementClass : ', toElementClass)
            if (
              toElementClass != 'popup_car_big_div' &&
              toElementClass != 'mapboxgl-popup-content'
            ) {
              marker.togglePopup()
            } else {
              // console.log('e.toElement.onmouseleave : ', e.toElement.onmouseleave)
              e.toElement.addEventListener('mouseleave', () => {
                if (popap.isOpen()) {
                  marker.togglePopup()
                }
              })
            }
          } else {
            marker.togglePopup()
          }
        } else {
          marker.togglePopup()
        }
      }
    }
  }

  static async add_marker_zone(lat, lng, map_object) {
    let new_id = map_object.new_markers_zones.length

    const marker_div = document.createElement('div')
    marker_div.className = 'zone_marker'
    marker_div.data_id = new_id

    let marker = new mapboxgl.Marker({ element: marker_div, draggable: false })
      .setLngLat([lat, lng])
      .addTo(map_object.map)

    marker.id = new_id
    return [marker, marker_div]
  }

  static async close_new_zone(map_object) {
    map_object.new_markers_zones.map(function (marker) {
      marker.remove()
    })
    map_object.new_markers_zones = []
    //end delete old stop markers

    //delete old lines
    map_object.new_lines_zones.map(function (line) {
      //console.log('remove line : ', line)
      map_object.map.removeLayer(line)
      map_object.map.removeSource(line)
    })

    if (map_object.map.getLayer('new_zone_poligon')) {
      map_object.map.removeLayer('new_zone_poligon')
      map_object.map.removeSource('new_zone_poligon')
    }

    map_object.new_lines_zones = []
  }

  static async change_geozone_color(zone_id, map_object) {
    if (map_object.map.getLayer('layer_geozone_' + zone_id)) {
      map_object.map.removeLayer('layer_geozone_' + zone_id)
      map_object.map.removeSource('layer_geozone_' + zone_id)
    }

    let obj = {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [],
        },
      },
    }

    let coordinates = []

    for (let zone of map_object.current_geozones) {
      if (zone.id == zone_id) {
        for (let line of zone.lines) {
          map_object.map.removeLayer(line)
          map_object.map.removeSource(line)
        }

        //draw lines
        let array_id = 0
        for (let point of zone.points) {
          let new_id = 'line_geozone_' + zone_id + '_' + array_id

          let source = {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [],
            },
          }

          let feature = {
            type: 'Feature',
            properties: {
              color: '#ffbc00',
            },
            geometry: {
              type: 'LineString',
              coordinates: [],
            },
          }

          let longitude
          let latitude
          let next_longitude
          let next_latitude

          if (array_id != zone.points.length - 1) {
            longitude = zone.points[array_id][1]
            latitude = zone.points[array_id][0]
            feature.geometry.coordinates.push([longitude, latitude])

            next_longitude = zone.points[array_id + 1][1]
            next_latitude = zone.points[array_id + 1][0]
            feature.geometry.coordinates.push([next_longitude, next_latitude])
          } else {
            longitude = zone.points[array_id][1]
            latitude = zone.points[array_id][0]
            feature.geometry.coordinates.push([longitude, latitude])

            next_longitude = zone.points[0][1]
            next_latitude = zone.points[0][0]
            feature.geometry.coordinates.push([next_longitude, next_latitude])
          }

          let id = new_id

          source.data.features.push(feature)

          map_object.map.addSource(id, source)
          map_object.map.addLayer({
            id: id,
            type: 'line',
            source: id,
            paint: {
              'line-width': 4,
              'line-color': ['get', 'color'],
            },
          })

          array_id = array_id + 1
        }
        //end draw lines

        //for layer
        for (let marker of zone.markers) {
          let coordinate = [marker._lngLat.lng, marker._lngLat.lat]
          coordinates.push(coordinate)
        }
      }
    }

    obj.data.geometry.coordinates.push(coordinates)

    map_object.map.addSource('layer_geozone_' + zone_id, obj)

    map_object.map.addLayer({
      id: 'layer_geozone_' + zone_id,
      type: 'fill',
      source: 'layer_geozone_' + zone_id,
      layout: {},
      paint: {
        'fill-color': '#ffbc00',
        'fill-opacity': 0.2,
      },
    })
  }

  static async draw_layer_new_zone(map_object) {
    if (map_object.map.getLayer('new_zone_poligon')) {
      map_object.map.removeLayer('new_zone_poligon')
      map_object.map.removeSource('new_zone_poligon')
    }

    let obj = {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [],
        },
      },
    }

    let coordinates = []

    for (let marker of map_object.new_markers_zones) {
      let coordinate = [marker._lngLat.lng, marker._lngLat.lat]
      coordinates.push(coordinate)
    }

    obj.data.geometry.coordinates.push(coordinates)

    map_object.map.addSource('new_zone_poligon', obj)

    map_object.map.addLayer({
      id: 'new_zone_poligon',
      type: 'fill',
      source: 'new_zone_poligon',
      layout: {},
      paint: {
        'fill-color': '#6486fb',
        'fill-opacity': 0.2,
      },
    })
  }

  static async draw_lines_new_zone(map_object) {
    let new_id = map_object.new_markers_zones.length - 1

    let source = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    }

    let feature = {
      type: 'Feature',
      properties: {
        color: '#6486fb',
      },
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
    }

    if (new_id > 0) {
      let old_longitude = map_object.new_markers_zones[new_id - 1]._lngLat.lng
      let old_latitude = map_object.new_markers_zones[new_id - 1]._lngLat.lat
      feature.geometry.coordinates.push([old_longitude, old_latitude])

      let longitude = map_object.new_markers_zones[new_id]._lngLat.lng
      let latitude = map_object.new_markers_zones[new_id]._lngLat.lat
      feature.geometry.coordinates.push([longitude, latitude])

      let id = new_id - 1
      id = 'new_line_zone_' + id

      source.data.features.push(feature)

      map_object.map.addSource(id, source)
      map_object.new_lines_zones.push(id)
      map_object.map.addLayer({
        id: id,
        type: 'line',
        source: id,
        paint: {
          'line-width': 4,
          'line-color': ['get', 'color'],
        },
      })

      //console.log('map_object.new_markers_zones : ', map_object.new_markers_zones)
    }
  }

  static async draw_current_geozones(сurrent_geozones, map_object) {
    //console.log('draw_current_geozones function start: ', map_object.current_geozones)

    if (!сurrent_geozones) {
      return
    }

    for (let geozone of сurrent_geozones) {
      //console.log('geozone draw: ', geozone)
      let geozone_id = geozone.id
      let geozone_name = geozone.name
      let points = geozone.points_json

      //console.log('geozone_name : ', geozone_name)

      let geozone_obj = {
        id: geozone_id,
        'name:': geozone_name,
        points: points,
        markers: [],
        lines: [],
        layer: null,
      }

      //console.log('points : ', points)

      if (!points.length) {
        console.log('у зоны нет точек')
        break
      }

      //draw markers
      for (let point of points) {
        let new_id = 'marker_geozone-' + geozone_id + '-' + geozone_obj.markers.length
        let lat = point[0]
        let lng = point[1]

        const marker_div = document.createElement('div')
        marker_div.className = 'zone_marker'
        marker_div.data_id = new_id

        let marker = new mapboxgl.Marker({ element: marker_div, draggable: false })
          .setLngLat([lng, lat])
          .addTo(map_object.map)

        marker.id = new_id

        geozone_obj.markers.push(marker)
      }
      //end draw markers

      //draw lines
      let array_id = 0
      for (let point of points) {
        let new_id = 'line_geozone_' + geozone_id + '_' + array_id

        let source = {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [],
          },
        }

        let feature = {
          type: 'Feature',
          properties: {
            color: '#6486fb',
          },
          geometry: {
            type: 'LineString',
            coordinates: [],
          },
        }

        let longitude
        let latitude
        let next_longitude
        let next_latitude

        if (array_id != points.length - 1) {
          longitude = points[array_id][1]
          latitude = points[array_id][0]
          feature.geometry.coordinates.push([longitude, latitude])

          next_longitude = points[array_id + 1][1]
          next_latitude = points[array_id + 1][0]
          feature.geometry.coordinates.push([next_longitude, next_latitude])
        } else {
          longitude = points[array_id][1]
          latitude = points[array_id][0]
          feature.geometry.coordinates.push([longitude, latitude])

          next_longitude = points[0][1]
          next_latitude = points[0][0]
          feature.geometry.coordinates.push([next_longitude, next_latitude])
        }

        let id = new_id

        source.data.features.push(feature)

        map_object.map.addSource(id, source)
        map_object.map.addLayer({
          id: id,
          type: 'line',
          source: id,
          paint: {
            'line-width': 4,
            'line-color': ['get', 'color'],
          },
        })

        array_id = array_id + 1
        geozone_obj.lines.push(new_id)
      }
      //end draw lines

      //draw_layer

      let layer_id = 'layer_geozone_' + geozone_id

      let layer_source = {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [],
          },
        },
      }

      let coordinates = []

      for (let point of points) {
        let coordinate = [point[1], point[0]]
        coordinates.push(coordinate)
      }

      // find center and add label
      let count = 0
      let allx = 0
      let ally = 0
      let seg = coordinates
      for (let i = 0; i < seg.length; i++) {
        allx += seg[i][0]
        ally += seg[i][1]
        count++
      }
      let center = [allx / count, ally / count]
      //console.log('center : ', center)

      const center_label = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              description: geozone.name,
              //'text-color': 'rgb(100, 134, 251)',
            },
            geometry: {
              type: 'Point',
              coordinates: center,
            },
          },
        ],
      }

      let layer_label_id = 'layer_geozone_label_' + geozone_id

      map_object.map.addSource(layer_label_id, {
        type: 'geojson',
        data: center_label,
      })

      map_object.map.addLayer({
        id: layer_label_id,
        type: 'symbol',
        source: layer_label_id,
        layout: {
          'text-field': ['get', 'description'],
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          //'text-color': 'rgb(100, 134, 251)',
          'text-radial-offset': 0,
          'text-justify': 'auto',
        },
        paint: {
          'text-color': '#3564fc',
        },
      })

      // end find center and add label

      layer_source.data.geometry.coordinates.push(coordinates)

      map_object.map.addSource(layer_id, layer_source)

      map_object.map.addLayer({
        id: layer_id,
        type: 'fill',
        source: layer_id,
        layout: {},
        //'text-field': 'Привет',
        paint: {
          'fill-color': '#6486fb',
          'fill-opacity': 0.2,
        },
      })

      geozone_obj.layer = layer_id
      geozone_obj.layer_label = layer_label_id

      //end draw layer

      map_object.current_geozones.push(geozone_obj)
    }

    //console.log('draw map_object.current_geozones function end : ', map_object.current_geozones)
  }

  static async hide_current_geozones(map_object) {
    //console.log('hide map_object.current_geozones function: start', map_object.current_geozones)

    for (let geozone of map_object.current_geozones) {
      // console.log('hide geozone : ', geozone)
      for (let marker of geozone.markers) {
        marker.remove()
      }
      geozone.markers = []

      for (let line of geozone.lines) {
        map_object.map.removeLayer(line)
        map_object.map.removeSource(line)
      }
      geozone.lines = []

      map_object.map.removeLayer(geozone.layer)
      map_object.map.removeSource(geozone.layer)

      map_object.map.removeLayer(geozone.layer_label)
      map_object.map.removeSource(geozone.layer_label)
      geozone.layer_label = null
      geozone.layer = null
    }

    map_object.current_geozones = []

    // map_object.map.removeLayer(line)
    // map_object.map.removeSource(line)
    //console.log('hide map_object.current_geozones function: end', map_object.current_geozones)
  }

  static async draw_last_line_new_zone(map_object) {
    let new_id = map_object.new_markers_zones.length - 1

    let source = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    }

    let feature = {
      type: 'Feature',
      properties: {
        color: '#6486fb',
      },
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
    }

    let old_longitude = map_object.new_markers_zones[new_id]._lngLat.lng
    let old_latitude = map_object.new_markers_zones[new_id]._lngLat.lat
    feature.geometry.coordinates.push([old_longitude, old_latitude])

    let longitude = map_object.new_markers_zones[0]._lngLat.lng
    let latitude = map_object.new_markers_zones[0]._lngLat.lat
    feature.geometry.coordinates.push([longitude, latitude])

    let id = 'new_line_zone_' + new_id

    source.data.features.push(feature)

    map_object.map.addSource(id, source)
    map_object.new_lines_zones.push(id)
    map_object.map.addLayer({
      id: id,
      type: 'line',
      source: id,
      paint: {
        'line-width': 4,
        'line-color': ['get', 'color'],
      },
    })
  }

  static async drag_marker_new_zone(map_object, marker_draged) {
    let source
    let feature

    //past line
    source = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    }

    feature = {
      type: 'Feature',
      properties: {
        color: '#6486fb',
      },
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
    }

    let line_id
    if (marker_draged.id != 0) {
      line_id = marker_draged.id - 1
      line_id = 'new_line_zone_' + line_id
    } else {
      line_id = map_object.new_markers_zones.length - 1
      line_id = 'new_line_zone_' + line_id
    }

    map_object.map.removeLayer(line_id)
    map_object.map.removeSource(line_id)

    let position = 0
    for (let line of map_object.new_lines_zones) {
      if (line == line_id) {
        map_object.new_lines_zones.splice(position, 1)
        break
      }
      position = position + 1
    }

    let old_longitude
    let old_latitude
    let longitude
    let latitude

    if (marker_draged.id != 0) {
      old_longitude = map_object.new_markers_zones[marker_draged.id - 1]._lngLat.lng
      old_latitude = map_object.new_markers_zones[marker_draged.id - 1]._lngLat.lat
      feature.geometry.coordinates.push([old_longitude, old_latitude])

      longitude = map_object.new_markers_zones[marker_draged.id]._lngLat.lng
      latitude = map_object.new_markers_zones[marker_draged.id]._lngLat.lat
      feature.geometry.coordinates.push([longitude, latitude])
    } else {
      old_longitude =
        map_object.new_markers_zones[map_object.new_markers_zones.length - 1]._lngLat.lng
      old_latitude =
        map_object.new_markers_zones[map_object.new_markers_zones.length - 1]._lngLat.lat
      feature.geometry.coordinates.push([old_longitude, old_latitude])

      longitude = map_object.new_markers_zones[marker_draged.id]._lngLat.lng
      latitude = map_object.new_markers_zones[marker_draged.id]._lngLat.lat
      feature.geometry.coordinates.push([longitude, latitude])
    }

    let id = line_id

    source.data.features.push(feature)

    map_object.map.addSource(id, source)

    map_object.new_lines_zones.push(id)

    map_object.map.addLayer({
      id: id,
      type: 'line',
      source: id,
      paint: {
        'line-width': 4,
        'line-color': ['get', 'color'],
      },
    })

    //next line
    source = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    }

    feature = {
      type: 'Feature',
      properties: {
        color: '#6486fb',
      },
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
    }

    line_id = 'new_line_zone_' + marker_draged.id

    map_object.map.removeLayer(line_id)
    map_object.map.removeSource(line_id)

    position = 0
    for (let line of map_object.new_lines_zones) {
      if (line == line_id) {
        map_object.new_lines_zones.splice(position, 1)
        break
      }
      position = position + 1
    }

    if (marker_draged.id != map_object.new_markers_zones.length - 1) {
      old_longitude = map_object.new_markers_zones[marker_draged.id]._lngLat.lng
      old_latitude = map_object.new_markers_zones[marker_draged.id]._lngLat.lat
      feature.geometry.coordinates.push([old_longitude, old_latitude])

      longitude = map_object.new_markers_zones[marker_draged.id + 1]._lngLat.lng
      latitude = map_object.new_markers_zones[marker_draged.id + 1]._lngLat.lat
      feature.geometry.coordinates.push([longitude, latitude])
    } else {
      old_longitude = map_object.new_markers_zones[marker_draged.id]._lngLat.lng
      old_latitude = map_object.new_markers_zones[marker_draged.id]._lngLat.lat
      feature.geometry.coordinates.push([longitude, latitude])

      longitude = map_object.new_markers_zones[0]._lngLat.lng
      latitude = map_object.new_markers_zones[0]._lngLat.lat
      feature.geometry.coordinates.push([longitude, latitude])
    }

    id = line_id

    source.data.features.push(feature)

    map_object.map.addSource(id, source)

    map_object.new_lines_zones.push(id)

    map_object.map.addLayer({
      id: id,
      type: 'line',
      source: id,
      paint: {
        'line-width': 4,
        'line-color': ['get', 'color'],
      },
    })
  }

  static async hide_routes(map_object) {
    //console.log('map_object : ', map_object)
    if (!map_object.map) {
      return
    }
    //delete old stop markers
    map_object.stop_markers_on_lines.map(function (marker) {
      marker.remove()
    })
    map_object.stop_markers_on_lines = []
    //end delete old stop markers

    //delete old speed markers
    map_object.speed_layers_id.map(function (id) {
      map_object.map.removeLayer('speed_layer___' + id)
    })
    map_object.speed_sourses_id.map(function (id) {
      map_object.map.removeSource('speed_source_' + id)
    })
    map_object.speed_layers_id = []
    map_object.speed_sourses_id = []
    //end delete old speed markers

    //delete old lines
    map_object.lines.map(function (line) {
      map_object.map.removeLayer(line)
      map_object.map.removeSource(line)
    })
    map_object.lines = []
    //end delete old lines
  }

  static async draw_routes(car_id, map_object) {
    console.log('draw_routes : ', car_id)

    if (!map_object.routes) {
      return
    }

    //speed_marker img
    if (map_object.info_draw_routes.first_init_img_routes) {
      for (let key in map_object.info_draw_routes.dots_for_speed) {
        let img = new Image(20, 20)
        img.onload = () => map_object.map.addImage('speed_marker_' + key, img)
        img.src = map_object.info_draw_routes.dots_for_speed[key]
      }
      map_object.info_draw_routes.first_init_img_routes = false
    }
    //speed_marker img

    //remove other marker, show only our
    map_object.markers.map(function (car_marker) {
      if (car_marker.id != car_id) {
        car_marker.marker.remove()
        //map_object.markers.splice(count, 1);
      }
    })
    //

    //delete old stop markers
    map_object.stop_markers_on_lines.map(function (marker) {
      marker.remove()
    })
    map_object.stop_markers_on_lines = []
    //end delete old stop markers

    //delete old speed markers
    map_object.speed_layers_id.map(function (id) {
      map_object.map.removeLayer('speed_layer___' + id)
    })
    map_object.speed_sourses_id.map(function (id) {
      map_object.map.removeSource('speed_source_' + id)
    })
    map_object.speed_layers_id = []
    map_object.speed_sourses_id = []
    //end delete old speed markers

    //delete old lines
    map_object.lines.map(function (line) {
      map_object.map.removeLayer(line)
      map_object.map.removeSource(line)
    })
    map_object.lines = []
    //end delete old lines

    let day_id = 0
    let line_id = 0
    let color_id = 0
    let speed_id = 0

    let count_of_moves = 0
    let count_of_stops = 0

    let last_feature

    let last_stop_longitude
    let last_stop_latitude
    let last_move_longitude
    let last_move_latitude

    let colors = map_object.info_draw_routes.colors

    // let colors = [ //если меняешь цвета, то нужно поменять еще и в отрисовке списка маршрутов
    //     '#6486FB',
    //     '#21AD7B',
    //     '#FBA364',
    //     '#FF0000',
    //     '#FFEB3B',
    //     '#FB6464',
    //     '#D464FB',
    //     '#FBD164',
    //     '#FB64C8',
    //     '#8B64FB',
    //     '#000F43',
    //     '#64FBA9',
    //     '#64FBFB',
    //     '#002FD0',
    // ]

    map_object.routes.map(function (day) {
      let longitude
      let latitude
      let course
      let start_dt
      let end_dt
      let distance

      let source = {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      }

      day.route.map(function (piece_of_way) {
        if (color_id > colors.length - 2) {
          color_id = 0
        }

        start_dt = piece_of_way.start_dt
        end_dt = piece_of_way.end_dt
        distance = piece_of_way.distance

        if (piece_of_way.type == 'move') {
          let feature = {
            type: 'Feature',
            properties: {
              color: colors[color_id],
            },
            geometry: {
              type: 'LineString',
              coordinates: [],
            },
          }

          count_of_moves = count_of_moves + 1

          let coordinate

          if (last_stop_longitude) {
            coordinate = [last_stop_longitude, last_stop_latitude]
            feature.geometry.coordinates.push(coordinate)
          }

          if (last_move_longitude) {
            coordinate = [last_move_longitude, last_move_latitude]
            feature.geometry.coordinates.push(coordinate)
          }

          piece_of_way.points.map(function (point) {
            // console.log('point : ', point)
            longitude = point.coords[0]
            latitude = point.coords[1]

            coordinate = [longitude, latitude]
            feature.geometry.coordinates.push(coordinate)

            //start add speed maker

            // if ((speed_id % 5 == 0)&&(map_object.period_for_routes.start == map_object.period_for_routes.end)){
            //     add_speed(speed_id, map_object, longitude, latitude, point, colors[color_id])
            // }

            // speed_id++

            // end add speed maker
          })

          last_feature = feature

          //console.log('feature : ', feature)

          //add lines
          source.data.features.push(feature)

          let id = 'line_' + line_id

          map_object.lines.push(id)

          map_object.map.addSource(id, source)

          map_object.map.addLayer({
            id: id,
            type: 'line',
            source: id,
            paint: {
              'line-width': 4,
              'line-color': ['get', 'color'],
            },
          })

          //end add lines

          // piece_of_way.points.map(function(point){
          //     // console.log('point : ', point)
          //     longitude = point.coords[0]
          //     latitude = point.coords[1]

          //     //start add speed maker
          //     if ((speed_id % 5 == 0)&&(map_object.period_for_routes.start == map_object.period_for_routes.end)){
          //         add_speed(speed_id, map_object, longitude, latitude, point, colors[color_id])
          //     }
          //     speed_id++
          //     // end add speed maker

          // })

          line_id = line_id + 1
          color_id = color_id + 1

          last_move_longitude = longitude
          last_move_latitude = latitude

          last_stop_longitude = null
          last_stop_latitude = null
        } else {
          count_of_stops = count_of_stops + 1

          //add stop maker
          longitude = piece_of_way.points[0].coords[0]
          latitude = piece_of_way.points[0].coords[1]
          course = piece_of_way.points[0].course

          const marker_div = document.createElement('div')
          marker_div.className = 'stop_marker'

          let time_start =
            start_dt.split(' ')[1].split(':')[0] + ':' + start_dt.split(' ')[1].split(':')[1]
          let time_end =
            end_dt.split(' ')[1].split(':')[0] + ':' + end_dt.split(' ')[1].split(':')[1]
          let date_start = start_dt.split(' ')[0]
          let date_end = end_dt.split(' ')[0]

          let popup =
            '<div class="">' +
            '<div class="div_popup_stop_marker">' +
            '<div class="popup_stop_text">Стоянка</div>' +
            '<div class="popup_range_div">' +
            '<div>' +
            '<div class="popup_time_text">' +
            time_start +
            '</div>' +
            '<div class="popup_date_text">' +
            date_start +
            '</div>' +
            '</div>' +
            '<span class="popup_minus_text">' +
            '-' +
            '</span>' +
            '<div>' +
            '<div class="popup_time_text">' +
            time_end +
            '</div>' +
            '<div class="popup_date_text">' +
            date_end +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>'

          let marker = new mapboxgl.Marker({ element: marker_div, rotation: '0' })
            .setLngLat([longitude, latitude])
            .setPopup(
              new mapboxgl.Popup({ closeButton: false, openOnClick: false })
                .setMaxWidth('400px')
                .setHTML(popup),
            )
            .addTo(map_object.map)

          //marker.on('drag', console.log('asdasdasd'))

          marker_div.addEventListener('mouseenter', () => marker.togglePopup())
          marker_div.addEventListener('mouseleave', () => marker.togglePopup())

          map_object.stop_markers_on_lines.push(marker)

          //end add stop maker

          last_stop_longitude = longitude
          last_stop_latitude = latitude
        }
      })
      // let array = map_object.map.style.order
      // let array2 = []
      // let pos = 0
      // for (let el of array){
      //     let el_new = el
      //     if (el_new.split('___')[0]){
      //         if (el_new.split('___')[0] == 'speed_layer'){
      //             array.splice(pos, 1);
      //             array2.push(el)
      //             pos--
      //         }
      //     }
      //     pos++
      // }
      // for (let el of array2){
      //     array.push(el)
      // }
      // map_object.map.style.order = array
    })

    if (count_of_moves != 0) {
      let coordinates = last_feature.geometry.coordinates

      // Create a 'LngLatBounds' with both corners at the first coordinate.
      let bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])

      // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
      for (let coord of coordinates) {
        bounds.extend(coord)
      }

      map_object.map.fitBounds(bounds, {
        padding: 50,
      })
    } else {
      if (count_of_stops != 0) {
        map_object.map.flyTo({
          center: [last_stop_longitude, last_stop_latitude],
          zoom: 15,
          speed: 0.1,
          curve: 1,
          duration: 3000,
          essential: true,
          easing(t) {
            return t
          },
        })
      } else {
        console.log('у маяка нет вообще никаких маршрутов за эту дату')
      }
    }

    let speed_layer_id = 0
    let speed_source_id = 0
    let color_id_2 = 0

    map_object.routes.map(function (day) {
      day.route.map(function (piece_of_way) {
        if (color_id_2 > colors.length - 2) {
          color_id_2 = 0
        }
        if (piece_of_way.type == 'move') {
          let source = {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [],
            },
          }

          piece_of_way.points.map(function (point) {
            // console.log('point : ', point)
            let longitude = point.coords[0]
            let latitude = point.coords[1]

            //start add speed maker

            let speed_date = point.dt.split(' ')[0]
            let speed_time = point.dt.split(' ')[1]
            let speed_value = point.vel

            let popup =
              '<div class="">' +
              '<div class="div_popup_speed_marker">' +
              '<div class="div_dt_popup_speed_marker">' +
              '<div class="time_popup_speed_marker">' +
              speed_time +
              '</div>' +
              '<div class="date_popup_speed_marker">' +
              speed_date +
              '</div>' +
              '</div>' +
              '<div class="div_vel_popup_speed_marker">' +
              '<div class="text_popup_speed_marker">' +
              'Скорость:' +
              '</div>' +
              '<div class="value_popup_speed_marker">' +
              speed_value +
              ' км/ч' +
              '</div>' +
              '</div>' +
              '</div>' +
              '</div>'

            let feature = {
              type: 'Feature',
              properties: {
                popup: popup,
              },
              geometry: {
                type: 'Point',
                coordinates: [longitude, latitude],
              },
            }

            if (
              speed_source_id % 5 == 0 &&
              map_object.period_for_routes.start == map_object.period_for_routes.end
            ) {
              source.data.features.push(feature)
            }

            speed_source_id++

            // end add speed maker
          })

          map_object.map.addSource('speed_source_' + speed_source_id, source)
          map_object.speed_sourses_id.push(speed_source_id)

          //
          add_speed_layer(speed_layer_id, map_object, colors[color_id_2], speed_source_id)

          speed_layer_id++
          color_id_2++
        }
      })
    })

    function add_speed_layer(speed_id, map_object, color, source_id) {
      // // console.log('point : ', point)
      // let speed_date = point.dt.split(' ')[0]
      // let speed_time = point.dt.split(' ')[1]
      // let speed_value = point.vel

      // let popup = '<div class="">' +
      //                 '<div class="div_popup_speed_marker">' +
      //                     '<div class="div_dt_popup_speed_marker">' +
      //                         '<div class="time_popup_speed_marker">' +
      //                             speed_time +
      //                         '</div>' +
      //                         '<div class="date_popup_speed_marker">' +
      //                             speed_date +
      //                         '</div>' +
      //                     '</div>' +
      //                     '<div class="div_vel_popup_speed_marker">' +
      //                         '<div class="text_popup_speed_marker">' +
      //                             'Скорость:' +
      //                         '</div>' +
      //                         '<div class="value_popup_speed_marker">' +
      //                             speed_value + ' км/ч' +
      //                         '</div>' +
      //                     '</div>' +
      //                 '</div>' +
      //             '</div>'

      map_object.map.addLayer({
        id: 'speed_layer___' + speed_id,
        type: 'symbol',
        metadata: '',
        source: 'speed_source_' + source_id, // reference the data source
        layout: {
          'icon-image': 'speed_marker_' + color, // reference the image
        },
      })

      let popup_obj

      map_object.map.on('mouseenter', 'speed_layer___' + speed_id, (e) => {
        // Copy coordinates array.
        const coordinates = e.features[0].geometry.coordinates.slice()
        const description = e.features[0].properties.popup

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }

        popup_obj = new mapboxgl.Popup({ closeButton: false, openOnClick: false })
          .setMaxWidth('400px')
          .setLngLat(coordinates)
          .setHTML(description)
          .addTo(map_object.map)

        map_object.map.getCanvas().style.cursor = 'pointer'
      })

      map_object.map.on('mouseleave', 'speed_layer___' + speed_id, () => {
        mouse_speed_leave(popup_obj)
      })

      map_object.speed_layers_id.push(speed_id)
    }

    function mouse_speed_leave(popup_obj) {
      popup_obj.remove()
      map_object.map.getCanvas().style.cursor = ''
    }
  }
}
