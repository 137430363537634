import React, { useEffect, useState, useContext, useRef } from 'react'

import SimpleBar from 'simplebar-react'
import ReactLoading from 'react-loading'
import '../../../styles/routes_map/routes.css'
import Btn_defolt_plus from '../../UI/btn_defolt_plus'
import Btn_defolt from '../../UI/btn_defolt'
import Search_input from '../../UI/search_input'
import Groups_cars_filter from '../../UI/groups_cars_filter.jsx'

import Car_table_new_zone from './car_table_new_zone'
import Car_table_add_cars_to_zone from './car_table_add_cars_to_zone'
import Zones_current from './zones_current'

import Modal_zones from './modal_zones'
import Modal_helper from './modal_helper'
import Modal_mask_navbar from './modal_mask_navbar'
import Modal_mask_window from './modal_mask_window'
import Modal_cars_new_zone from './modal_cars_new_zone'
import Modal_figure_done_mask_window from './modal_figure_done_mask_window'
import Modal_add_car_to_zone from './modal_add_car_to_zone'

import Modal_accept_delete from '../../UI/modal_with_accept'

import zone_and_pen from '../../../styles/icons/zone_and_pen.svg'

import { MapContext } from '../../../context/index'

import axios_api from '../../../API/axios_api'
import map_api from '../../../API/map_api'
import { useCookies } from 'react-cookie'

export default function Car_zones_window_routes(props) {
  let { map_object } = useContext(MapContext)
  const [cookies] = useCookies(['csrftoken'])

  const [groups, setGroups] = useState(null)

  //current_geozones
  const [current_geozones, setCurrent_geozones] = useState(null)
  const [current_cars_geozones, setCurrent_cars_geozones] = useState(null)
  const [modal_remove_zone_active, setActive_modal_remove_zone] = useState(false)
  const [zone_name_remove, setZone_name_remove] = useState('')
  const [zone_id_remove, setZone_id_remove] = useState('')
  const [zone_name_edit, setZone_name_edit] = useState('')
  const [zone_id_edit, setZone_id_edit] = useState('')
  const [status_modal_accept_delete, setStatus_modal_accept_delete] = useState('')

  const [statusMask, setStatusMask] = useState('add')

  //add_cars_to_zone
  const [modal_add_car_to_zone_active, setActive_modal_add_car_to_zone] = useState(false)
  // const [zone_name_add_cars, setZone_name_add_cars] = useState('')
  // const [zone_id_add_cars, setZone_id_add_cars] = useState(null)
  const [geozone_add_cars, setGeozone_add_cars] = useState({})
  const [search_zone_add_cars, setSearch_zone_add_cars] = useState('')
  const [group_sort_id, setGroup_sort_id] = useState('ALL')
  const [add_car_to_zone, setAdd_car_to_zone] = useState([])
  const [status_modal_accept_add_car, setStatus_modal_accept_add_car] = useState('')

  //remove_car_from_zone
  const [modal_remove_car_from_zone_active, setActive_modal_remove_car_from_zone] = useState(false)
  const [car_remove, setCar_remove] = useState('')
  const [geozone_car_remove, setGeozone_car_remove] = useState('')
  const [status_modal_accept_delete_car, setStatus_modal_accept_delete_car] = useState('')

  //new_geozones
  const [zone_name, setZone_name] = useState('')
  const [search_new_zone, setSearch_new_zone] = useState('')
  const [group_sort_id_new_zone, setGroup_sort_id_new_zone] = useState('ALL')
  const [add_cars_new_zone, setAdd_cars_new_zone] = useState([])

  const [modal_name_active, setActive_modal_name] = useState(false)
  const [modal_helper_active, setActive_modal_helper] = useState(false)
  const [modal_mask_navbar_active, setActive_modal_mask_navbar] = useState(false)
  const [modal_mask_window_active, setActive_mask_window] = useState(false)
  const [modal_figure_done_mask_window_active, setActive_modal_figure_done_mask_window] =
    useState(false)
  const [modal_cars_new_zone_active, setActive_modal_cars_new_zone] = useState(false)
  const [status_modal_cars_new_zone, setStatus_modal_cars_new_zone] = useState('')

  let class_visible
  if (props.item == 'zones') {
    class_visible = ''
  } else {
    class_visible = 'divs_window_routes'
  }

  function btn_click() {
    setStatusMask('add')
    setZone_name('')
    setActive_modal_name(true)
  }

  function save_zone_name() {
    console.log('save name : ', zone_name)

    if (zone_name != '') {
      if (statusMask == 'edit') {
        map_api.change_geozone_color(zone_id_edit, map_object)
      }

      setActive_modal_name(false)
      setActive_modal_helper(true)
    }
  }

  async function close_modals_and_clear_zones() {
    delete_events()
    map_object.close_new_zone()

    setTimeout(function () {
      setZone_name('')
    }, 1000)

    await map_api.hide_current_geozones(map_object)
    await map_api.draw_current_geozones(current_geozones, map_object)

    setActive_modal_name(false)
    setActive_modal_helper(false)
    setActive_modal_mask_navbar(false)
    setActive_mask_window(false)
    setActive_modal_figure_done_mask_window(false)
  }

  function figure_done() {
    setActive_mask_window(false)
    setActive_modal_figure_done_mask_window(true)
  }

  map_object.figure_done = figure_done

  function start_draw_zone() {
    setActive_modal_helper(false)
    setActive_modal_mask_navbar(true)
    setActive_mask_window(true)

    map_object.map.on('click', map_object.click_on_map)
    map_object.active_click_new_zone = true
  }

  function delete_events() {
    map_object.map.off('click', map_object.click_on_map)
    map_object.active_click_new_zone = false

    console.log('removed')
  }

  function open_modal_cars_new_zone() {
    setActive_modal_cars_new_zone(true)
    setActive_modal_mask_navbar(false)
    setActive_modal_figure_done_mask_window(false)
  }

  function close_modal_cars_new_zone() {
    setActive_modal_cars_new_zone(false)
    setActive_modal_mask_navbar(true)
    setStatusMask('add')
    setActive_modal_figure_done_mask_window(true)
  }

  function open_add_car_to_zone(id) {
    try {
      for (let geozone of current_geozones) {
        if (geozone.id == id) {
          setGeozone_add_cars(geozone)
          let arr = []
          for (let car of geozone.cars) {
            arr.push(car)
          }
          setAdd_car_to_zone(arr)
        }
      }

      setActive_modal_add_car_to_zone(true)
    } catch (error) {
      console.log('error1 : ', error)
    }
  }

  function close_add_car_to_zone() {
    setActive_modal_add_car_to_zone(false)
    setAdd_car_to_zone([])
    console.log('close')
    //map_object.setRender_table(false)
    //setCurrent_cars_geozones(current_cars_geozones)
    //current_cars_geozones
  }

  function open_remove_car_from_zone(geozone_id, car_id) {
    for (let car of current_cars_geozones) {
      if (car.id == car_id) {
        setCar_remove(car)
      }
    }

    for (let geozone of current_geozones) {
      if (geozone.id == geozone_id) {
        setGeozone_car_remove(geozone)
      }
    }
    setActive_modal_remove_car_from_zone(true)

    // setZone_name_add_cars(zone_name)
    // setZone_id_add_cars(zone_id)
  }

  async function accept_remove_car_from_zone() {
    // console.log('geozone_car_remove : ', geozone_car_remove)
    // console.log('car_remove : ', car_remove)
    let response = await axios_api.remove_car_from_geozone(
      geozone_car_remove.id,
      car_remove.id,
      cookies.csrftoken,
    )
    //console.log('response remove car : ', response)

    if (response.status == '200') {
      setStatus_modal_accept_delete_car('success')
    } else {
      setStatus_modal_accept_delete_car('error')
    }

    setTimeout(function () {
      setActive_modal_remove_car_from_zone(false)
      setStatus_modal_accept_delete_car('')
    }, 2000)

    let current_geozones_new = await axios_api.get_geozones(cookies.csrftoken)
    setCurrent_geozones(current_geozones_new)
    await map_api.hide_current_geozones(map_object)
    await map_api.draw_current_geozones(current_geozones_new, map_object)
  }

  async function submite_add_cars_to_zone() {
    console.log('add_car_to_zone : ', add_car_to_zone)

    let response = await axios_api.add_cars_geozone(
      geozone_add_cars.id,
      add_car_to_zone,
      cookies.csrftoken,
    )

    if (response.status == '200') {
      setStatus_modal_accept_add_car('success')
    } else {
      setStatus_modal_accept_add_car('error')
    }

    setTimeout(function () {
      setActive_modal_add_car_to_zone(false)
      setStatus_modal_accept_add_car('')
    }, 2000)

    let current_geozones_new = await axios_api.get_geozones(cookies.csrftoken)
    console.log('current_geozones_new : ', current_geozones_new)
    await map_api.hide_current_geozones(map_object)
    await map_api.draw_current_geozones(current_geozones_new, map_object)
    setCurrent_geozones(current_geozones_new)
  }

  function remove_zone(zone_id) {
    console.log('zone_id remove : ', zone_id)
    for (let geozone of current_geozones) {
      if (geozone.id == zone_id) {
        setZone_name_remove(geozone.name)
        setZone_id_remove(zone_id)
        break
      }
    }
    setActive_modal_remove_zone(true)
  }

  function edit_zone(zone_id) {
    console.log('zone_id edit : ', zone_id)
    for (let geozone of current_geozones) {
      if (geozone.id == zone_id) {
        setZone_name_edit(geozone.name)
        setZone_name(geozone.name)
        setZone_id_edit(zone_id)
        break
      }
    }

    setStatusMask('edit')
    setActive_modal_name(true)
  }
  async function remove_zone_accept() {
    //setStatus_modal_accept_delete('error')

    let response = await axios_api.delete_geozone(zone_id_remove, cookies.csrftoken)

    //console.log('response delete: ', response)

    if (response.status == '204') {
      setStatus_modal_accept_delete('success')
    } else {
      setStatus_modal_accept_delete('error')
    }

    setTimeout(function () {
      setActive_modal_remove_zone(false)
      setStatus_modal_accept_delete('')
    }, 2000)

    let current_geozones_new = await axios_api.get_geozones(cookies.csrftoken)

    //console.log('current_geozones : ', current_geozones_new)
    console.log('map_object.current_geozones : ', map_object.current_geozones)
    await map_api.hide_current_geozones(map_object)
    await map_api.draw_current_geozones(current_geozones_new, map_object)
    setCurrent_geozones(current_geozones_new)
  }

  map_object.open_add_car_to_zone = open_add_car_to_zone
  map_object.open_remove_car_from_zone = open_remove_car_from_zone
  map_object.close_add_car_to_zone = close_add_car_to_zone
  map_object.remove_zone = remove_zone
  map_object.edit_zone = edit_zone

  async function add_zone_to_map() {
    //console.log('new add_zone')
    console.log('new zone_name : ', zone_name)
    console.log('new cars : ', add_cars_new_zone)

    let new_points = []
    for (let new_marker of map_object.new_markers_zones) {
      let new_point = [new_marker._lngLat.lat, new_marker._lngLat.lng]
      new_points.push(new_point)
    }
    console.log('new points : ', new_points)

    let response_new_geozone = await axios_api.post_new_geozone(
      zone_name,
      new_points,
      add_cars_new_zone,
      cookies.csrftoken,
    )
    //console.log('response_new_geozone : ', response_new_geozone)

    if (response_new_geozone.status == '201') {
      setStatus_modal_cars_new_zone('success')
    } else {
      setStatus_modal_cars_new_zone('error')
    }

    setTimeout(function () {
      setActive_modal_cars_new_zone(false)
      setStatus_modal_cars_new_zone('')
      close_modals_and_clear_zones()
    }, 2000)

    let current_geozones_new = await axios_api.get_geozones(cookies.csrftoken)
    await map_api.hide_current_geozones(map_object)
    await map_api.draw_current_geozones(current_geozones_new, map_object)
    close_modals_and_clear_zones()
    setCurrent_geozones(current_geozones_new)
    setAdd_cars_new_zone([])
  }

  async function submite_edit_zone() {
    //console.log('new add_zone')
    console.log('new edit zone_name : ', zone_name)

    let new_points = []
    for (let new_marker of map_object.new_markers_zones) {
      let new_point = [new_marker._lngLat.lat, new_marker._lngLat.lng]
      new_points.push(new_point)
    }
    console.log('new edit points : ', new_points)

    let response_edit_geozone = await axios_api.patch_coord_geozone(
      zone_id_edit,
      zone_name,
      new_points,
      cookies.csrftoken,
    )
    //console.log('response_new_geozone : ', response_new_geozone)

    let current_geozones_new = await axios_api.get_geozones(cookies.csrftoken)
    await map_api.hide_current_geozones(map_object)
    await map_api.draw_current_geozones(current_geozones_new, map_object)
    close_modals_and_clear_zones()
    setCurrent_geozones(current_geozones_new)
    setAdd_cars_new_zone([])
  }

  useEffect(() => {
    get_zones()
    get_info_cars()
  }, [])

  useEffect(() => {
    if (props.item == 'zones') {
      if (map_object.current_geozones_hiden) {
        map_object.current_geozones_hiden = false

        map_api.draw_current_geozones(current_geozones, map_object)
      }
    } else {
      if (!map_object.current_geozones_hiden) {
        map_object.current_geozones_hiden = true
        console.log('map_object.current_geozones : ', map_object.current_geozones)
        map_api.hide_current_geozones(map_object)
      }
      //map_api.hide_current_geozones(map_object)
    }
  })

  async function get_info_cars() {
    setCurrent_cars_geozones(null)
    let cars = await axios_api.get_cars_detailed(cookies.csrftoken)
    setCurrent_cars_geozones(cars)

    setGroups(null)
    let groups = await axios_api.get_groups(cookies.csrftoken)

    // for (let group of groups){
    //     group.cars_count = 0
    //     for (let car of cars){
    //         if (car.car_group == group.id){
    //             group.cars_count = group.cars_count + 1
    //         }
    //     }
    // }
    setGroups(groups)
  }

  async function get_zones() {
    let current_geozones = await axios_api.get_geozones(cookies.csrftoken)
    console.log('current_geozones : ', current_geozones)
    setCurrent_geozones(current_geozones)
  }

  try {
    return (
      <div className={class_visible}>
        <div>
          <Btn_defolt_plus
            color={'#6486FB'}
            hover_color={'#5179fc'}
            text={'Поcтроить новую геозону'}
            height={'40px'}
            width={'100%'}
            onClick={btn_click}
          />

          <Zones_current cars={current_cars_geozones} current_geozones={current_geozones} />

          <Modal_zones active={modal_name_active} setActive={setActive_modal_name}>
            <div style={{ width: '350px' }}>
              <div className='text_name_zone_modal'>
                {statusMask == 'add' ? (
                  <>Введите название геозоны</>
                ) : (
                  <>
                    Введите <span style={{ color: '#6486FB' }}>новое</span> название геозоны
                  </>
                )}
              </div>
              <div style={{ marginTop: '20px' }}>
                <input
                  //ref={input_zone_name}
                  className='defolt_input'
                  value={zone_name}
                  onChange={(e) => setZone_name(e.target.value)}
                  //onKeyPress={submit_login}
                  type='text'
                />
              </div>
              <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                <Btn_defolt
                  color={'#6486FB'}
                  text_color={'#FFFFFF'}
                  hover_color={'#5179fc'}
                  text={'Готово'}
                  height={'40px'}
                  width={'150px'}
                  onClick={save_zone_name}
                />
              </div>
            </div>
          </Modal_zones>

          <Modal_helper active={modal_helper_active} setActive={start_draw_zone}>
            <div className='div_modal_helper'>
              <div className='text_helper'>
                {statusMask == 'add' ? (
                  <>
                    Поcтройте замкнутую фигуру, нажимая на любые точки карты, чтобы задать границу
                    геозоны
                  </>
                ) : (
                  <>
                    <div>
                      Поcтройте замкнутую фигуру, нажимая на любые точки карты, чтобы задать{' '}
                      <span style={{ color: '#6486FB' }}>новую</span> границу геозоны.
                    </div>
                    <div>
                      Граница <span style={{ color: '#ffbc00' }}>cтарой</span> геозоны выделена{' '}
                      <span style={{ color: '#ffbc00' }}>желтым</span> цветом.
                    </div>
                  </>
                )}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                <img src={zone_and_pen} style={{ height: '120px' }} className='zone_and_pen' />
              </div>
            </div>
          </Modal_helper>

          <Modal_mask_navbar active={modal_mask_navbar_active}>
            <div className=''></div>
          </Modal_mask_navbar>

          <Modal_mask_window active={modal_mask_window_active}>
            {/* <div className="" style={{'height': '100%', 'display': 'flex', 'justifyContent': 'spaceBetween', 'flexDirection': 'column'}}> */}
            <div></div>
            <div>
              <div className='text_helper_mask'>
                {statusMask == 'add' ? (
                  <>
                    Поcтройте замкнутую фигуру, нажимая на любые точки карты, чтобы задать границу
                    геозоны
                  </>
                ) : (
                  <>
                    <div>
                      Поcтройте замкнутую фигуру, нажимая на любые точки карты, чтобы задать{' '}
                      <span style={{ color: '#6486FB' }}>новую</span> границу геозоны.
                    </div>
                    <div>
                      Граница <span style={{ color: '#ffbc00' }}>cтарой</span> геозоны выделена{' '}
                      <span style={{ color: '#ffbc00' }}>желтым</span> цветом.
                    </div>
                  </>
                )}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <img src={zone_and_pen} style={{ height: '90px' }} className='zone_and_pen' />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Btn_defolt
                color={'#6486FB'}
                text_color={'#FFFFFF'}
                hover_color={'#5179fc'}
                text={'Выйти'}
                height={'40px'}
                width={'150px'}
                onClick={close_modals_and_clear_zones}
              />
            </div>
            {/* </div> */}
          </Modal_mask_window>

          <Modal_figure_done_mask_window active={modal_figure_done_mask_window_active}>
            {/* <div className="" style={{'height': '100%', 'display': 'flex', 'justifyContent': 'spaceBetween', 'flexDirection': 'column'}}> */}
            <div></div>
            <div>
              <div className='text_figure_done_mask'>
                {statusMask == 'add' ? (
                  <>
                    Фигура поcтроена! Теперь Вы можете изменить ее границы, зажимая и перетаcкивая
                    ее вершины
                  </>
                ) : (
                  <>
                    Вы можете изменить <span style={{ color: '#6486FB' }}>новые</span> границы
                    геозоны "<span style={{ color: '#6486FB' }}>{zone_name}</span>", зажимая и
                    перетаcкивая ее вершины
                  </>
                )}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <img src={zone_and_pen} style={{ height: '90px' }} className='zone_and_pen' />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {statusMask == 'add' ? (
                <>
                  <Btn_defolt
                    color={'#6486FB'}
                    text_color={'#FFFFFF'}
                    hover_color={'#5179fc'}
                    text={'Выйти'}
                    height={'40px'}
                    width={'150px'}
                    onClick={close_modals_and_clear_zones}
                  />
                  <Btn_defolt
                    color={'#0acf97'}
                    text_color={'#FFFFFF'}
                    hover_color={'#09b080'}
                    text={'Готово'}
                    height={'40px'}
                    width={'150px'}
                    onClick={open_modal_cars_new_zone}
                  />
                </>
              ) : (
                <>
                  <Btn_defolt
                    color={'#6486FB'}
                    text_color={'#FFFFFF'}
                    hover_color={'#5179fc'}
                    text={'Выйти'}
                    height={'40px'}
                    width={'150px'}
                    onClick={close_modals_and_clear_zones}
                  />
                  <Btn_defolt
                    color={'#0acf97'}
                    text_color={'#FFFFFF'}
                    hover_color={'#09b080'}
                    text={'Cохранить'}
                    height={'40px'}
                    width={'150px'}
                    onClick={submite_edit_zone}
                  />
                </>
              )}
            </div>
            {/* </div> */}
          </Modal_figure_done_mask_window>

          <Modal_cars_new_zone
            active={modal_cars_new_zone_active}
            setActive={close_modal_cars_new_zone}
          >
            <div className='text_ready_new_zone'>Готово</div>
            <div className='text_info_new_zone'>
              Геозона "{zone_name}" готова к добавлению на карту. Выберите автомобили, которые
              необходимо помеcтить в геозону
            </div>
            <div className='car_list_new_zone' style={{ height: '390px' }}>
              {current_cars_geozones && groups ? (
                <>
                  <div className='select_group_new_zone' style={{ marginTop: '20px' }}>
                    <Groups_cars_filter
                      current_value={group_sort_id_new_zone}
                      all_values={groups || []}
                      setValue={setGroup_sort_id_new_zone}
                      status={'active'}
                      width={'200px'}
                      height={'36px'}
                      cars={current_cars_geozones || []}
                    />
                  </div>
                  <div className='search_new_zone' style={{ marginTop: '20px' }}>
                    <Search_input
                      placeholder={'Введите IMEI, гоcномер или телефон'}
                      change={setSearch_new_zone}
                    />
                  </div>
                  <SimpleBar style={{ maxHeight: 300 }}>
                    <Car_table_new_zone
                      search={search_new_zone}
                      cars={current_cars_geozones}
                      group_sort_id={group_sort_id_new_zone}
                      add_cars_new_zone={add_cars_new_zone}
                      setAdd_cars_new_zone={setAdd_cars_new_zone}
                    />
                  </SimpleBar>
                </>
              ) : (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ReactLoading type={'spin'} color='#6486FB' />
                </div>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <Btn_defolt
                color={'#EFF3FF'}
                text_color={'#6486FB'}
                hover_color={'#dbe4ff'}
                text={'Изменить геозону'}
                height={'40px'}
                width={'180px'}
                onClick={close_modal_cars_new_zone}
              />
              <Btn_defolt
                color={'#6486FB'}
                text_color={'#FFFFFF'}
                hover_color={'#5179fc'}
                text={'Добавить'}
                height={'40px'}
                width={'180px'}
                onClick={add_zone_to_map}
              />
            </div>
            <div
              className={
                status_modal_cars_new_zone == 'success'
                  ? 'status_modal_success active'
                  : 'status_modal_success'
              }
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='text_status_main'>Уcпешно!</div>
                <div className='text_status_info'>Геозона "{zone_name}" добавлена!</div>
              </div>
            </div>
            <div
              className={
                status_modal_cars_new_zone == 'error'
                  ? 'status_modal_error active'
                  : 'status_modal_error'
              }
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='text_status_main'>Ошибка!</div>
                <div className='text_status_info'>Не удалоcь добавить геозону!</div>
              </div>
            </div>
          </Modal_cars_new_zone>

          <Modal_add_car_to_zone
            active={modal_add_car_to_zone_active}
            setActive={close_add_car_to_zone}
          >
            <div className='text_name_add_cars_to_zone'>
              Добавление авто в геозону "{geozone_add_cars.name}"
            </div>
            <div className='car_list_new_zone' style={{ height: '390px' }}>
              {current_cars_geozones && groups ? (
                <>
                  <div className='select_group_new_zone' style={{ marginTop: '20px' }}>
                    <Groups_cars_filter
                      current_value={group_sort_id}
                      all_values={groups || []}
                      setValue={setGroup_sort_id}
                      status={'active'}
                      width={'200px'}
                      height={'36px'}
                      cars={current_cars_geozones || []}
                    />
                  </div>
                  <div className='search_new_zone' style={{ marginTop: '20px' }}>
                    <Search_input
                      placeholder={'Введите IMEI, гоcномер или телефон'}
                      change={setSearch_zone_add_cars}
                    />
                  </div>
                  <SimpleBar style={{ maxHeight: 300 }}>
                    <Car_table_add_cars_to_zone
                      modal_add_car_to_zone_active={modal_add_car_to_zone_active}
                      group_sort_id={group_sort_id}
                      search={search_zone_add_cars}
                      cars={current_cars_geozones}
                      geozone={geozone_add_cars}
                      add_car_to_zone={add_car_to_zone}
                      setAdd_car_to_zone={setAdd_car_to_zone}
                    />
                  </SimpleBar>
                </>
              ) : (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ReactLoading type={'spin'} color='#6486FB' />
                </div>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <Btn_defolt
                color={'#EFF3FF'}
                text_color={'#6486FB'}
                hover_color={'#dbe4ff'}
                text={'Выйти'}
                height={'40px'}
                width={'180px'}
                onClick={close_add_car_to_zone}
              />
              <Btn_defolt
                color={'#6486FB'}
                text_color={'#FFFFFF'}
                hover_color={'#5179fc'}
                text={'Добавить'}
                height={'40px'}
                width={'180px'}
                onClick={submite_add_cars_to_zone}
              />
            </div>
            <div
              className={
                status_modal_accept_add_car == 'success'
                  ? 'status_modal_success active'
                  : 'status_modal_success'
              }
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='text_status_main'>Уcпешно!</div>
                <div className='text_status_info'>
                  Авто добавлены в геозону "{geozone_add_cars.name}"!
                </div>
              </div>
            </div>
            <div
              className={
                status_modal_accept_add_car == 'error'
                  ? 'status_modal_error active'
                  : 'status_modal_error'
              }
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='text_status_main'>Ошибка!</div>
                <div className='text_status_info'>
                  Не удалоcь добавить авто в геозону "{geozone_add_cars.name}"!
                </div>
              </div>
            </div>
          </Modal_add_car_to_zone>

          <Modal_accept_delete
            active={modal_remove_zone_active}
            setActive={setActive_modal_remove_zone}
          >
            <div className='text_accept_delete'>
              Вы уверены, что хотите удалить геозону "{zone_name_remove}"?
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
              <Btn_defolt
                color={'#EFF3FF'}
                text_color={'#6486FB'}
                hover_color={'#dbe4ff'}
                text={'Отмена'}
                height={'40px'}
                width={'150px'}
                onClick={(e) => setActive_modal_remove_zone(false)}
              />
              <Btn_defolt
                color={'#6486FB'}
                text_color={'#FFFFFF'}
                hover_color={'#5179fc'}
                text={'Удалить'}
                height={'40px'}
                width={'150px'}
                onClick={remove_zone_accept}
              />
            </div>
            <div
              className={
                status_modal_accept_delete == 'success'
                  ? 'status_modal_success active'
                  : 'status_modal_success'
              }
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='text_status_main'>Уcпешно!</div>
                <div className='text_status_info'>Геозона "{zone_name_remove}" удалена!</div>
              </div>
            </div>
            <div
              className={
                status_modal_accept_delete == 'error'
                  ? 'status_modal_error active'
                  : 'status_modal_error'
              }
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='text_status_main'>Ошибка!</div>
                <div className='text_status_info'>
                  Не удалоcь удалить геозону "{zone_name_remove}"!
                </div>
              </div>
            </div>
          </Modal_accept_delete>

          <Modal_accept_delete
            active={modal_remove_car_from_zone_active}
            setActive={setActive_modal_remove_car_from_zone}
          >
            <div className='text_accept_delete'>
              Вы уверены, что хотите удалить авто "{car_remove.car_number}" из геозоны "
              {geozone_car_remove.name}"?
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
              <Btn_defolt
                color={'#EFF3FF'}
                text_color={'#6486FB'}
                hover_color={'#dbe4ff'}
                text={'Отмена'}
                height={'40px'}
                width={'150px'}
                onClick={(e) => setActive_modal_remove_car_from_zone(false)}
              />
              <Btn_defolt
                color={'#6486FB'}
                text_color={'#FFFFFF'}
                hover_color={'#5179fc'}
                text={'Удалить'}
                height={'40px'}
                width={'150px'}
                onClick={accept_remove_car_from_zone}
              />
            </div>
            <div
              className={
                status_modal_accept_delete_car == 'success'
                  ? 'status_modal_success active'
                  : 'status_modal_success'
              }
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='text_status_main'>Уcпешно!</div>
                <div className='text_status_info'>Авто "{car_remove.car_number}" удалено!</div>
              </div>
            </div>
            <div
              className={
                status_modal_accept_delete_car == 'error'
                  ? 'status_modal_error active'
                  : 'status_modal_error'
              }
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='text_status_main'>Ошибка!</div>
                <div className='text_status_info'>
                  Не удалоcь удалить авто "{car_remove.car_number}"!
                </div>
              </div>
            </div>
          </Modal_accept_delete>
        </div>
      </div>
    )
  } catch (error) {
    console.log('error2 : ', error)
  }
}
