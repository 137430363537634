import '../../../styles/routes_map/car_table_zone.css'

export default function Car_table_new_zone_item(props) {
  let car = props.car

  if (car.car_number == null) {
    car.car_number = '-'
  }
  if (car.brand == null) {
    car.brand = '-'
  }

  if (car.phone_number == null) {
    car.phone_number = '-'
  }

  function check_box_click(e) {
    console.log('click_id : ', e.currentTarget.getAttribute('data_id'))
    console.log('data_checked : ', e.currentTarget.getAttribute('data_checked'))

    let id = parseFloat(e.currentTarget.getAttribute('data_id'))
    let checked = e.currentTarget.getAttribute('data_checked') == 'false'

    let count = 0
    let find = false

    for (let car_id_in_array of props.add_cars_new_zone) {
      if (car_id_in_array == id) {
        find = true
        break
      }
      count = count + 1
    }

    if (checked) {
      if (!find) {
        props.add_cars_new_zone.push(id)
      }
    } else {
      if (find) {
        props.add_cars_new_zone.splice(count, 1)
        //delete props.add_cars_new_zone[count]
      }
    }
    props.setAdd_cars_new_zone(props.add_cars_new_zone)
    props.setRender_table(new Date())
  }

  return (
    <div
      className='table_zone_row'
      data_checked={props.checked == 'checked' ? 'true' : 'false'}
      data_id={car.id}
      onClick={(e) => check_box_click(e)}
    >
      <div className='table_zone_div'>
        <div className='table_zone_td_checkbox'>
          <input
            className='form-check-input'
            type='checkbox'
            checked={props.checked == 'checked'}
          />
        </div>
        <div className='table_zone_td_img'>
          <img src={props.car_img} style={{ height: '25px' }} />
        </div>
        <div className='table_zone_td_number'>
          <div className='table_zone_number'>{car.car_number}</div>
          <div className='table_zone_brand'>{car.brand}</div>
        </div>
        <div className='table_zone_td_phone_number'>{car.phone_number}</div>
      </div>
      <div className='div_border_bottom_table_zone_row'>
        <div className='border_bottom_table_zone_row'></div>
      </div>
    </div>
  )
}
