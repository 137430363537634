import React, { useEffect, useContext } from 'react'
import Draw_report from '../components/report/draw_report.jsx'

import { useDispatch, useSelector } from 'react-redux'
import resize_action from '../actions/window_actions.js'
import page_change from '../actions/router_actions.js'
import { MapContext } from '../context/index'
import $ from 'jquery'
import Modal_bills from '../components/map/bills_modal/modal_bills.jsx'

const MainReport = () => {
  const payment_block = useSelector((state) => state.payment_block)
  let { map_object } = useContext(MapContext)
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = 'Отчет | ControlGPS'
    let height = $('#root').height()
    let width = $('#root').width()
    dispatch(resize_action(height, width))
    dispatch(page_change('report'))
    map_object.markers = []
    map_object.map = null
  }, [])

  return (
    <>
      <Modal_bills hideIfNotMaxDiff={true} />
      {!payment_block && <Draw_report />}
    </>
  )
}

export default MainReport
