import React, { useMemo } from 'react'
import Basic_select from './basic_select'

export default function Groups_cars_filter(props) {
  const newAllValues = useMemo(() => {
    const arr =
      props.type == 'aggregators'
        ? [['Все агрегаторы', 'ALL']]
        : [[`Все группы (${props.cars ? props.cars.length : 0})`, 'ALL']]

    for (const item of props.all_values) {
      if (props.type == 'aggregators') {
        let agg_name = item.name
        if (agg_name.length > 12) {
          agg_name = agg_name.substr(0, 12) + '...'
        }
        let count = 0
        for (const car of props.cars) {
          if (car.aggregator == item.id) {
            count++
          }
        }
        agg_name = agg_name + ' (' + count + ')'
        arr.push([agg_name, item.id])
      } else {
        let group_name = item.name
        if (group_name.length > 12) {
          group_name = group_name.substr(0, 12) + '...'
        }
        group_name = group_name + ' (' + item.cars.length + ')'
        arr.push([group_name, item.id])
      }
    }

    return arr
  }, [props.all_values, props.cars, props.type])

  return (
    <Basic_select
      current_value={props.current_value}
      all_values={newAllValues}
      setValue={props.setValue}
      status={props.status}
      width={props.width}
      height={props.height}
    />
  )
}
