/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import '../../styles/car_list/car_list_main.css'
import { useCookies } from 'react-cookie'
import axios_api from '../../API/axios_api'
import Basic_input from '../UI/basic_input'
import List_input from '../UI/list_input'
import Modal_with_accept from '../UI/modal_with_accept'
import Car_color from './car_color.jsx'
import Car_commands from './car_commands.jsx'
import Car_info from './car_info.jsx'
import Car_chart_mileage from './car_chart_mileage.jsx'
import Car_chart_activity from './car_chart_activity.jsx'
import Car_geozones from './car_geozones.jsx'
import CarInspections from './CarInspections/carInspections.jsx'
import Basic_select from '../UI/basic_select.jsx'
import { useDispatch, useSelector } from 'react-redux'
import resize_action from '../../actions/window_actions.js'
import $ from 'jquery'
import CodeInfoModal from './CarMobile/CodeInfoModal.jsx'

export default function Car_profile(props) {
  const dispatch = useDispatch()
  const user_current = useSelector((state) => state.user_current)
  const canEditCar = user_current.permissions.includes('EDIT_CAR')
  const [cookies] = useCookies(['csrftoken'])

  const [number, setNumber] = useState('')
  const [brand, setBrand] = useState('')
  const [model, setModel] = useState('')
  const [mil, setMil] = useState('')

  const [status_number, setStatus_number] = useState('active')
  const [status_brand, setStatus_brand] = useState('active')
  const [status_model, setStatus_model] = useState('active')
  const [status_mil, setStatus_mil] = useState('active')
  const [status_color, setStatus_color] = useState('active')
  const [status_aggregator, setStatus_aggregator] = useState('active')

  const [modal_aggregator_active, setActive_modal_aggregator] = useState(false)
  const [status_modal_aggregator, setStatus_modal_aggregator] = useState('')
  const [aggregator_value_new, setAggregator_value_new] = useState('')

  const [aggregator_value, setAggregator_value] = useState(null)

  const [modal_car_code_active, setActive_modal_car_code] = useState(false)

  let aggregator_filter = []
  if (props.aggregators_for_select) {
    aggregator_filter.push(['Без агрегатора', null])
    for (let aggregator of props.aggregators_for_select) {
      aggregator_filter.push([aggregator.name, aggregator.id])
    }
  }

  async function onChangeNumber(val) {
    if (!canEditCar) return
    setNumber(val)
    send_changes('number', val)
  }

  async function onChangeModel(val) {
    if (!canEditCar) return
    setModel(val)
    send_changes('model', val)
  }

  async function onChangeMil(val) {
    if (!canEditCar) return
    setMil(val)
    send_changes('mil', val)
  }

  async function onChangeBrand(val) {
    if (!canEditCar) return
    setBrand(val)
    send_changes('brand', val)
  }

  async function onChangeAggregator(val) {
    if (!canEditCar) return
    for (let agg of aggregator_filter) {
      if (agg[1] == val) {
        setAggregator_value_new(agg[0])
      }
    }
    setActive_modal_aggregator(true)
    send_changes('aggregator', val)
  }

  async function onChangeColor(val) {
    if (!canEditCar) return
    send_changes('color', val)
  }

  async function send_changes(type, val) {
    if (!canEditCar) return
    if (type == 'number') {
      let resp_number = await axios_api.car_number(val, props.car.id, cookies.csrftoken)
      if (resp_number.status == 200) {
        setStatus_number('success')
      } else {
        setStatus_number('error')
      }
      setTimeout(function () {
        setStatus_number('active')
      }, 2000)
    }

    if (type == 'brand') {
      let resp_brand = await axios_api.car_brand(val, props.car.id, cookies.csrftoken)
      if (resp_brand.status == 200) {
        setStatus_brand('success')
      } else {
        setStatus_brand('error')
      }
      setTimeout(function () {
        setStatus_brand('active')
      }, 2000)
    }

    if (type == 'model') {
      let resp_model = await axios_api.car_model(val, props.car.id, cookies.csrftoken)
      if (resp_model.status == 200) {
        setStatus_model('success')
      } else {
        setStatus_model('error')
      }
      setTimeout(function () {
        setStatus_model('active')
      }, 2000)
    }

    if (type == 'mil') {
      let resp_mil = await axios_api.car_mil(val, props.car.id, cookies.csrftoken)
      if (resp_mil.status == 200) {
        setStatus_mil('success')
      } else {
        setStatus_mil('error')
      }
      setTimeout(function () {
        setStatus_mil('active')
      }, 2000)
    }

    if (type == 'color') {
      let resp_color = await axios_api.car_color(val, props.car.id, cookies.csrftoken)
      if (resp_color.status == 200) {
        setStatus_color('success')
      } else {
        setStatus_color('error')
      }
      setTimeout(function () {
        setStatus_color('active')
      }, 2000)
    }

    if (type == 'aggregator') {
      let resp_aggregator = await axios_api.car_aggregator(val, props.car.id, cookies.csrftoken)
      console.log(resp_aggregator)
      if (resp_aggregator == 'success') {
        setStatus_aggregator('success')
        setStatus_modal_aggregator('success')
        setAggregator_value(val)
      } else {
        setStatus_modal_aggregator('error')
        setStatus_aggregator('error')
      }
      setTimeout(function () {
        setActive_modal_aggregator(false)
        setStatus_aggregator('active')
        setStatus_modal_aggregator('')
      }, 1500)
    }

    setTimeout(function () {
      setStatus_number('active')
      setStatus_color('active')
      setStatus_brand('active')
      setStatus_aggregator('active')
      setStatus_mil('active')
    }, 2000)

    props.get_cars_detailed()
    props.get_cars_brands_and_models()
  }

  useEffect(() => {
    if (props.car) {
      setNumber(props.car.car_number)
      setBrand(props.car.brand)
      setAggregator_value(props.car.aggregator)
      setModel(props.car.model)
      setMil(Math.round(parseFloat(props.car.mileage)))
    }
  }, [props.car_profile_id])

  useEffect(() => {
    if (props.car) {
      for (let car of props.cars) {
        if (car.id == props.car.id) {
          props.setCar_profile(car)
        }
      }
    }
    let height = $(document).height()
    let width = $(document).width()
    dispatch(resize_action(height, width))
  }, [props.car, props.cars])

  async function command_geozone_engine_click() {
    if (!canEditCar) return
    console.log('command_geozone_engine_click')
    await axios_api.car_geozone_block(
      !props.car.geozone_engine_block,
      props.car.id,
      cookies.csrftoken,
    )
    props.get_cars_detailed()
  }

  return (
    <div className='' style={{ height: '100%' }}>
      <div
        className='text_car_profile'
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div>Настройки автомобиля</div>
        <div>
          {props.car && props.car.is_mobile ? (
            <div className='text_car_profile_code' onClick={() => setActive_modal_car_code(true)}>
              Показать данные временного авто
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {props.car ? (
        <>
          <div
            style={{
              display: 'flex',
              marginTop: '30px',
              justifyContent: 'space-between',
            }}
          >
            <Basic_input
              value={number}
              text={'Номер автомобиля'}
              status={canEditCar ? status_number : 'readonly'}
              width={'calc(50% - 12px)'}
              type={'text'}
              onChange={onChangeNumber}
            />
            <List_input
              value={brand}
              text={'Марка автомобиля'}
              status={canEditCar ? status_brand : 'readonly'}
              width={'calc(50% - 12px)'}
              type={'text'}
              onChange={onChangeBrand}
              source={props.cars_brands}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: '30px',
              justifyContent: 'space-between',
            }}
          >
            {user_current && user_current.balance_blocking ? (
              <div style={{ width: 'calc(50% - 12px)' }}>
                <div className='aggregator_text_car_profile'>Агрегатор</div>
                <Basic_select
                  current_value={aggregator_value}
                  all_values={aggregator_filter}
                  setValue={onChangeAggregator}
                  width={'100%'}
                  height={'46px'}
                  status={canEditCar ? status_aggregator : 'readonly'}
                />
              </div>
            ) : (
              <></>
            )}
            <List_input
              value={model}
              text={'Модель автомобиля'}
              width={'calc(50% - 12px)'}
              type={'text'}
              onChange={onChangeModel}
              source={props.cars_models}
              status={canEditCar ? status_model : 'readonly'}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: '30px',
              position: 'relative',
              width: 'calc(50% - 12px)',
            }}
          >
            <Basic_input
              value={mil}
              text={'Текущий пробег'}
              rightText={'КМ'}
              status={canEditCar ? status_mil : 'readonly'}
              width={'100%'}
              type={'text'}
              onChange={onChangeMil}
            />
          </div>
          {user_current.permissions.includes('ENGINE_BLOCK') ? (
            <div
              style={{
                marginTop: '30px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '18px',
              }}
            >
              <input
                style={{ fontSize: '16px' }}
                className='form-check-input'
                checked={props.car.geozone_engine_block}
                type='checkbox'
                onChange={() => command_geozone_engine_click()}
              />
              <div
                style={{ fontSize: '16px' }}
                className={
                  props.car.geozone_engine_block
                    ? 'payment_checkbox_text active'
                    : 'payment_checkbox_text'
                }
              >
                Автоматическая блокировка двигателя при выезде из геозоны
              </div>
            </div>
          ) : (
            <></>
          )}
          <div style={{ marginTop: '30px' }}>
            <Car_color
              car={props.car}
              get_cars_detailed={props.get_cars_detailed}
              setColor={onChangeColor}
              status={status_color}
            />
          </div>
          <div style={{ display: 'flex', marginTop: '30px' }}>
            <Car_commands
              car={props.car}
              cars={props.cars}
              get_cars_detailed={props.get_cars_detailed}
            />
            <Car_info car={props.car} get_cars_detailed={props.get_cars_detailed} />
          </div>
          <div style={{ display: 'flex', marginTop: '45px' }}>
            <Car_chart_mileage car={props.car} />
          </div>
          <div style={{ display: 'flex', marginTop: '45px' }}>
            <Car_chart_activity car={props.car} />
          </div>
          <div style={{ display: 'flex', marginTop: '45px' }}>
            <CarInspections
              car={props.car}
              cars={props.cars}
              get_cars_detailed={props.get_cars_detailed}
            />
          </div>
          <div style={{ display: 'flex', marginTop: '45px' }}>
            <Car_geozones
              car={props.car}
              geozones={props.geozones}
              get_geozones={props.get_geozones}
            />
          </div>
        </>
      ) : (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ReactLoading type={'spin'} color='#6486FB' />
        </div>
      )}

      <Modal_with_accept active={modal_aggregator_active} setActive={setActive_modal_aggregator}>
        <div className='text_accept_delete'>
          Подождите, идет поиск автомобиля в агрегаторе '{aggregator_value_new}'
        </div>
        <div style={{ width: '100%', height: '200px' }}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ReactLoading type={'spin'} color='#6486FB' />
          </div>
        </div>
        <div
          className={
            status_modal_aggregator == 'success'
              ? 'status_modal_success active'
              : 'status_modal_success'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='text_status_main'>Успешно!</div>
            <div className='text_status_info'>Агрегатор авто изменен!</div>
          </div>
        </div>
        <div
          className={
            status_modal_aggregator == 'error' ? 'status_modal_error active' : 'status_modal_error'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='text_status_main'>Ошибка!</div>
            <div className='text_status_info'>
              Авто не найдено в агрегаторе '{aggregator_value_new}'
            </div>
          </div>
        </div>
      </Modal_with_accept>
      <CodeInfoModal
        car={props.car}
        active={modal_car_code_active}
        setActive={setActive_modal_car_code}
      />
    </div>
  )
}
