import React, { useEffect, useState, useContext } from 'react'
import '../../styles/car_list/car_list_main.css'
import ReactLoading from 'react-loading'
import { useCookies } from 'react-cookie'
import axios_api from '../../API/axios_api'

import geozone_active_car_profile from '../../styles/icons/geozone_active_car_profile.svg'
import geozone_inactive_car_profile from '../../styles/icons/geozone_inactive_car_profile.svg'

import power_off_icon from '../../styles/icons/power_off_icon.svg'
import power_on_icon from '../../styles/icons/power_on_icon.svg'

import Tippy from '@tippyjs/react/headless'

export default function Car_geozones(props) {
  const [cookies] = useCookies(['csrftoken'])
  // console.log('geo')

  function check_car_in_geozone(car_id, geozone) {
    if (geozone.cars.includes(car_id)) {
      return true
    } else {
      return false
    }
  }

  async function add_to_geozone(e) {
    let geozone_id = e.currentTarget.getAttribute('data_id')
    let response = await axios_api.add_car_to_geozone(geozone_id, props.car.id, cookies.csrftoken)
    props.get_geozones()
  }

  async function remove_from_geozone(e) {
    let geozone_id = e.currentTarget.getAttribute('data_id')
    let response = await axios_api.remove_car_from_geozone(
      geozone_id,
      props.car.id,
      cookies.csrftoken,
    )
    props.get_geozones()
  }

  return (
    <div style={{ width: '100%' }}>
      <div className='car_profile_chart_header'>Геозоны</div>
      {props.geozones != null && props.car != null ? (
        <>
          {props.geozones.length === 0 ? (
            <div className='car_geozones_main'>
              <div className='geozone_name_car_list' style={{ marginBottom: '16px' }}>
                У Вас нет геозон
              </div>
            </div>
          ) : (
            <div className='car_geozones_main'>
              {props.geozones.map((geozone) => (
                <div key={'geozone_' + geozone.id} className='geozone_item'>
                  <div>
                    {check_car_in_geozone(props.car.id, geozone) ? (
                      <img src={geozone_active_car_profile} />
                    ) : (
                      <img src={geozone_inactive_car_profile} />
                    )}
                    <span
                      className={
                        check_car_in_geozone(props.car.id, geozone)
                          ? 'geozone_name_car_list active'
                          : 'geozone_name_car_list'
                      }
                    >
                      {geozone.name}
                    </span>
                  </div>
                  {check_car_in_geozone(props.car.id, geozone) ? (
                    <Tippy
                      render={(attrs) => (
                        <div className='box tippy_content' tabIndex='-1' {...attrs}>
                          Убрать авто из геозоны '{geozone.name}'
                        </div>
                      )}
                    >
                      <img
                        src={power_on_icon}
                        style={{ cursor: 'pointer', height: '24px' }}
                        data_id={geozone.id}
                        onClick={(e) => remove_from_geozone(e)}
                      />
                    </Tippy>
                  ) : (
                    <Tippy
                      render={(attrs) => (
                        <div className='box tippy_content' tabIndex='-1' {...attrs}>
                          Добавить авто в геозону '{geozone.name}'
                        </div>
                      )}
                    >
                      <img
                        src={power_off_icon}
                        style={{ cursor: 'pointer', height: '24px' }}
                        data_id={geozone.id}
                        onClick={(e) => add_to_geozone(e)}
                      />
                    </Tippy>
                  )}
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            height: '150px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ReactLoading type={'spin'} color='#6486FB' />
        </div>
      )}
    </div>
  )
}
