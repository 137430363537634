import React, { useEffect, useState } from 'react'
import '../../../styles/park_settings/main_park_settings.css'
import 'react-slidedown/lib/slidedown.css'
import Btn_defolt from '../../UI/btn_defolt.jsx'
import Basic_input from '../../UI/basic_input.jsx'
import Fake_btn_defolt from '../../UI/fake_btn_defolt.jsx'
import axios_api from '../../../API/axios_api'
import { useCookies } from 'react-cookie'
import Slider from 'react-input-slider'
import { useDispatch } from 'react-redux'
import { park_loaded } from '../../../actions/park_actions.js'

export default function Geolocation(props) {
  const dispatch = useDispatch()
  const [cookies] = useCookies(['csrftoken'])

  const [status_save_edit_btn, setStatus_save_edit_btn] = useState('')
  const [status_save_edit_inputs, setStatus_save_edit_inputs] = useState('active')

  const [map_lat, setMap_lat] = useState('')
  const [map_lon, setMap_lon] = useState('')

  const [slider, setSlider] = useState({ x: 1 })

  async function save_edit() {
    let response_edit = await axios_api.park_geolocation_edit(
      map_lat,
      map_lon,
      slider.x * 8,
      cookies.csrftoken,
    )

    if (response_edit.status == '200') {
      setStatus_save_edit_btn('success')
      setStatus_save_edit_inputs('success')
    } else {
      setStatus_save_edit_btn('error')
      setStatus_save_edit_inputs('error')
    }

    setTimeout(function () {
      setStatus_save_edit_btn(null)
      setStatus_save_edit_inputs('active')
    }, 2000)

    let park_info = await axios_api.get_settings_current(cookies.csrftoken)
    dispatch(park_loaded(park_info))

    props.update_all()
  }

  useEffect(() => {
    if (props.settings_current) {
      setMap_lat(props.settings_current.map_default_lat)
      setMap_lon(props.settings_current.map_default_lon)
      setSlider({ x: props.settings_current.map_default_zoom / 8 })
    }
  }, [])

  return (
    <div className='div_main_setting'>
      <div className='header_refill_settings'>
        <div className='text_header_refill_settings'>Геопозиция парка</div>
      </div>
      <div style={{ marginTop: '30px', marginLeft: '16px', display: 'flex' }}>
        <div>
          <Basic_input
            value={map_lat}
            text={'Широта'}
            status={status_save_edit_inputs}
            width={'200px'}
            type={'text'}
            onChange={setMap_lat}
          />
        </div>
        <div style={{ marginLeft: '16px' }}>
          <Basic_input
            value={map_lon}
            text={'Долгота'}
            status={status_save_edit_inputs}
            width={'200px'}
            type={'text'}
            onChange={setMap_lon}
          />
        </div>
      </div>
      <div style={{ marginTop: '30px', marginLeft: '16px' }}>
        <div
          className={'text_checkbox_with_text ' + status_save_edit_btn}
          style={{ marginLeft: '0px' }}
        >
          Зум при загрузке карты
        </div>
        <div style={{ marginLeft: '10px', width: '200px', marginTop: '7px' }}>
          <Slider
            xmax={2}
            axis='x'
            xstep={0.1}
            x={slider.x}
            xmin={0.3}
            onChange={setSlider}
            styles={{
              active: {
                backgroundColor: '#6486FB',
              },
            }}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div className='text_basic_input'>0.3x</div>
          <div className='text_basic_input' style={{ marginLeft: '55px' }}>
            1.0x
          </div>
          <div className='text_basic_input' style={{ marginLeft: '95px' }}>
            2.0x
          </div>
        </div>
      </div>
      <div style={{ marginTop: '30px', marginLeft: '16px' }}>
        {status_save_edit_btn ? (
          <>
            {status_save_edit_btn == 'success' ? (
              <Fake_btn_defolt
                color={'#0acf97'}
                text_color={'#FFFFFF'}
                hover_color={'#0acf97'}
                text={'Сохранено!'}
                height={'40px'}
                width={'250px'}
              />
            ) : (
              <Fake_btn_defolt
                color={'#fa5c7c'}
                text_color={'#FFFFFF'}
                hover_color={'#fa5c7c'}
                text={'Ошибка!'}
                height={'40px'}
                width={'250px'}
              />
            )}
          </>
        ) : (
          <Btn_defolt
            color={'#0acf97'}
            text_color={'#FFFFFF'}
            hover_color={'#07b584'}
            text={'Сохранить изменения'}
            height={'40px'}
            width={'250px'}
            onClick={save_edit}
          />
        )}
      </div>
    </div>
  )
}
