export function park_loaded(park) {
  return {
    type: 'PARK_LOADED',
    payload: { park_current: park },
  }
}

export function payment_block(val) {
  return {
    type: 'PAYMENT_BLOCK',
    payload: { payment_block: val },
  }
}
