import React, { useEffect, useState } from 'react'

import '../../../styles/notifications/notifications.css'
import sos_active_icon from '../../../styles/icons/sos_active_icon.svg'
import sos_inactive_icon from '../../../styles/icons/sos_inactive_icon.svg'
import close_notification from '../../../styles/icons/close_notification.svg'

import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import Btn_defolt from '../../UI/btn_defolt'

export default function Notification_card(props) {
  let notification = props.notification

  if (!notification.car_number) {
    notification.car_number = 'НЕТНОМЕРА'
  }

  //datetime: "2022-10-20T00:20:24.278613"
  let display_date
  let time = notification.datetime.split('T')[1].substr(0, 5)
  let date =
    notification.datetime.split('T')[0].split('-')[2] +
    '.' +
    notification.datetime.split('T')[0].split('-')[1] +
    '.' +
    notification.datetime.split('T')[0].split('-')[0]
  let day = notification.datetime.split('T')[0].split('-')[2]
  let month = notification.datetime.split('T')[0].split('-')[1]
  let year = notification.datetime.split('T')[0].split('-')[0]

  let date_now = new Date()

  if (date_now.getDate() != day || date_now.getMonth() != month - 1) {
    display_date = day + '.' + month
  } else {
    display_date = ''
  }

  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const canBeOpen = open && Boolean(anchorEl)
  const id = canBeOpen ? 'transition-popper' : undefined

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  function go_dashboard() {
    window.history.replaceState(null, 'New Page Title', '/dashboard')
    window.location.reload()
  }

  if (notification.visible_status != 'not_active') {
    return (
      <>
        <div className='notification_card_div' onClick={go_dashboard}>
          <div
            className='notification_card'
            onMouseEnter={handleClick}
            onMouseLeave={() => setOpen(false)}
          >
            <div className='notification_card_header'>
              <div className='notification_car_number'>{notification.car_number}</div>
              <div className='notification_datetime'>
                <div className='notification_time'>{display_date}</div>
                <div>{time}</div>
              </div>
            </div>
            <div className='notification_message'>{notification.message}</div>
          </div>
          <Popper placement='top-start' id={id} open={open} anchorEl={anchorEl} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={0}>
                {/* <div className='hide_notification_icon' style={{'width': '30px', 'height': '30px', 'background': '#6486FB', 'borderRadius': '10px'}} data_message={notification.message} data_car_number={notification.car_number} data_id={notification.id} onClick={(e) => props.hide_notification(e)} onMouseEnter={(e) => setOpen(true)} onMouseLeave={() => setOpen(false)}></div> */}
                <img
                  className='hide_notification_icon'
                  src={close_notification}
                  style={{}}
                  data_message={notification.message}
                  data_car_number={notification.car_number}
                  data_id={notification.id}
                  onClick={(e) => props.hide_notification(e)}
                  onMouseEnter={(e) => setOpen(true)}
                  onMouseLeave={() => setOpen(false)}
                />
              </Fade>
            )}
          </Popper>
        </div>
        {notification.visible_status == 'last' && (
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: '12px', width: '100%' }}
          >
            <Btn_defolt
              color={'#EFF3FF'}
              text_color={'#6486FB'}
              hover_color={'#dbe4ff'}
              text={'Больше'}
              height={'35px'}
              width={'250px'}
              onClick={props.open_all_notifications}
            />
          </div>
        )}
      </>
    )
  }

  if (notification.visible_status == 'not_active') {
    return <div></div>
  }
}
